import {useCallback, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useDebounce} from '../../../../../components/hooks/useDebounce'
import {useEntityFilter} from '../../../../../components/hooks/useEntityFilter'
import {useLoadingState} from '../../../../../components/hooks/useLoadingState'
import {useRootStateSelector} from '../../../../../components/hooks/useRootStateSelector'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {ControlledFilterTable} from '../../../../../components/tables/ControlledFilterTable/ControlledFilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {TableRowId} from '../../../../../components/tables/TableRow'
// import {BillingHistoryModel} from '../../../../../models/BillingHistoryModel'
import {BookingModel} from '../../../../../models/ems/BookingModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {HasCode, idExtractor} from '../../../../../utils/idExtractor'
import {actions} from '../../../redux/CustomerDelegateRedux'
import {BillingHistoryTableRowActions} from './BillingHistoryTableRowActions'

export const BillingHistoryTable = () => {
  const [selected, setSelected] = useState<TableRowId[]>([])
  const bookings = useRootStateSelector((state) => state.customerDelegates.bookings)
  //test data change to billing history redux
  const [expandedGroups, setExpandedGroups] = useState<TableRowId[]>([])
  const {setFilter, filters} = useEntityFilter('customer-portal-booking')
  const searchDebounce = useDebounce(200)
  const dispatch = useDispatch()
  const {isKeyLoading} = useLoadingState()
  const columns = useMemo(() => {
    let standardColumns: TableColumnOptions<BookingModel>[] = [
      {
        field: 'code',
        label: 'Reservation Number',
        hideable: true,
        sortable: true,
        cellStyle: ColumnStyle.CODE,
      },

      {
        field: 'status',
        label: 'Amount',
        sortable: true,
        hideable: true,
      },
    ]

    return standardColumns
  }, [])

  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['draft', 'confirmed', 'fulfilled', 'active'],
      },
      groupBy: '',
    }

    return filters
  }, [])

  const onDownload = useCallback(async (booking) => {
    // console.log('download', booking)
  }, [])

  const isDataLoading = useCallback(
    (booking: HasCode) => {
      return isKeyLoading(booking.code)
    },
    [isKeyLoading]
  )

  const rowActions = useCallback(
    (data: BookingModel) => (
      <BillingHistoryTableRowActions
        loading={isDataLoading(data)}
        data={data}
        onDownload={onDownload}
      />
    ),
    [isDataLoading, onDownload]
  )

  const refresh = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.bookings.delegatesSearch())
    })
  }, [dispatch, searchDebounce])

  const handleFilter = useCallback(
    (filter: FilterModel) => {
      setFilter({
        ...filter,
        filters: {},
        groupBy: '',
      })
      refresh()
    },
    [setFilter, refresh]
  )

  const memoizedFilters = useMemo(() => {
    return filters || {}
  }, [filters])

  return (
    <ControlledFilterTable<BookingModel>
      initialFilters={initialFilters}
      onFilter={handleFilter}
      idExtractor={idExtractor}
      columns={columns}
      filters={memoizedFilters}
      searchResults={bookings}
      actions={rowActions}
      selection={selected}
      onSelectionChange={setSelected}
      expandedGroups={expandedGroups}
      onExpandedGroupsChange={setExpandedGroups}
      filterOnMount
    />
  )
}
