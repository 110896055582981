import {useMemo} from 'react'
import {useAuthRole} from '../../../../components/hooks/useAuthRole'
import {Auth} from '../../../../utils/RBAC/Role'
import {
  AuthNavigationSectionMenuItem,
  NavigationSectionMenuItem,
} from '../../../../../config/drawer'

export const useAuthMenus = (sections: NavigationSectionMenuItem[]) => {
  const authRole = useAuthRole()

  const rbacMenus = useMemo((): NavigationSectionMenuItem[] => {
    if (!authRole) {
      return []
    }
    return filterMenuAvailability(sections, authRole)
  }, [authRole, sections])

  return rbacMenus
}

const filterMenuAvailability = (
  sections: NavigationSectionMenuItem[],
  auth: Auth
): NavigationSectionMenuItem[] => {
  const rbacMenus: NavigationSectionMenuItem[] = []
  sections.forEach((menu) => {
    if (userHasAccessToMenu(menu, auth)) {
      if (menu.subMenus) {
        const subMenus = getMenuItems(menu.subMenus, auth)
        if (subMenus.length) {
          rbacMenus.push({
            ...menu,
            subMenus,
          })
        }
      } else {
        rbacMenus.push(menu)
      }
    }
  })
  return rbacMenus
}

const getMenuItems = (
  menus: AuthNavigationSectionMenuItem[],
  auth: Auth
): AuthNavigationSectionMenuItem[] => {
  const rbacMenuItems: AuthNavigationSectionMenuItem[] = []

  menus.forEach((menu) => {
    if (menu.featureCode) {
      if (auth.canReadFeature(menu.featureCode)) {
        rbacMenuItems.push(menu)
      }
    } else {
      rbacMenuItems.push(menu)
    }
  })

  return rbacMenuItems
}

const userHasAccessToMenu = (menu: AuthNavigationSectionMenuItem, auth: Auth) => {
  if (menu.isAdminOnly) {
    return auth.isAdmin()
  }
  if (!menu.featureCode && !menu.applicationCode) {
    return true
  }
  if (menu.featureCode && auth.canReadFeature(menu.featureCode)) {
    return true
  }
  if (menu.applicationCode) {
    const application = auth.getApplication(menu.applicationCode)
    if (application) {
      if (auth.isAdmin()) {
        return true
      } else {
        return application.hasAnyFeatures()
      }
    }
  }
  return false
}
