import {useCallback, useEffect, useMemo} from 'react'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useSeatMapPortalReservationState} from '../../hooks/useSeatMapPortalReservationState'
import {SeatMapSelectionPortalInput} from './SeatMapSelectionInput'
import {SeatMapValue} from '../../../../components/inputs/SeatMapInput/SeatMapValue'
import {GetvailableSeatMaps} from '../../redux/CustomerPortalCRUD'
import {useCustomerAuth} from '../../hooks/useCustomerAuth'
import {useSafeStateUpdate} from '../../../../components/hooks/useSafeStateUpdate'
import {GetLocationByCode} from '../../../default/acs/redux/AcsCRUD'
import {TicketModel} from '../../../../models/ems/TicketModel'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'

interface TicketsSeatManifestProps {
  locationCode?: string
  productCode?: string
  eventCode?: string
  currentTicket?: TicketModel
}
export const TicketsSeatManifest = ({
  locationCode,
  productCode,
  eventCode,
  currentTicket,
}: TicketsSeatManifestProps) => {
  const auth = useCustomerAuth()
  const {
    resetState,
    columns,
    rows,
    extra,
    disabled,
    shared,
    active,
    hidden,
    isRightToLeft,
    isBottomToTop,
    setAvailableSeates,
    setDisabled,
  } = useSeatMapPortalReservationState()
  const {push, pushError} = useAlerts()
  const safeUpdate = useSafeStateUpdate()
  const customerCode = useMemo(() => {
    return auth?.getUser().code
  }, [auth])

  const resetLocationByCode = useCallback(async () => {
    try {
      if (locationCode) {
        const {data} = await GetLocationByCode(locationCode)
        if (!data.seatMap) {
          push({
            message: `No seat maps available for ${data.name}`,
            variant: 'danger',
            timeout: 5000,
          })
        } else {
          safeUpdate(() => resetState(data.seatMap))
        }
      }
    } catch (e) {
      pushError(e)
    } finally {
      if (currentTicket) {
        if (customerCode) {
          let availableSeat = Object.assign({})
          availableSeat[currentTicket.seatRow] = [currentTicket.seatNo]
          setDisabled(new SeatMapValue(availableSeat))
        }
      } else {
        if (customerCode && locationCode && productCode && eventCode) {
          const {
            data: {availableSeats},
          } = await GetvailableSeatMaps(locationCode, productCode, eventCode, customerCode)
          setAvailableSeates(new SeatMapValue(availableSeats))
        }
      }
    }
  }, [
    locationCode,
    push,
    safeUpdate,
    resetState,
    pushError,
    currentTicket,
    customerCode,
    setDisabled,
    productCode,
    eventCode,
    setAvailableSeates,
  ])

  useEffect(() => {
    if (customerCode) {
      resetLocationByCode()
    }
  }, [customerCode, resetLocationByCode])

  const occupied = useMemo(() => {
    let activeSeats = new SeatMapValue()
    if (extra) {
      activeSeats = activeSeats.union(extra)
    }
    if (active) {
      activeSeats = activeSeats.union(active)
    }
    if (shared) {
      activeSeats = activeSeats.union(shared)
    }
    return new SeatMapValue(rows, columns).difference(activeSeats)
  }, [active, columns, extra, rows, shared])

  return (
    <>
      <div className='d-flex mb-4 justify-items-center'>
        <MetronicIcon className='me-1' iconType='Map' iconName='Marker1' size='svg-icon-1' />
        <h4 className='mb-0'>{currentTicket?.location?.name}</h4>
      </div>
      <SeatMapSelectionPortalInput
        columns={columns}
        rows={rows}
        occupied={currentTicket ? new SeatMapValue() : occupied}
        shared={shared}
        extra={extra}
        disabled={disabled}
        hidden={hidden}
        isRightToLeft={isRightToLeft}
        isBottomToTop={isBottomToTop}
      />
    </>
  )
}
