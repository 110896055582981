import {useCallback, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useDrawerRef} from '../../../components/Drawer/useDrawerRef'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {FilterModel} from '../../../models/FilterModel'
import {BookingCardGrid} from '../components/BookingCard/BookingCardGrid'
import {BookingTable} from '../components/tables/BookingTable/BookingTable'
import {actions} from '../redux/CustomerPortalRedux'
import {
  BookingPortalFormCreate,
  BookingPortalFormEdit,
} from '../components/DrawerForm/BookingPortalFormDrawer'
import {GetBookingByCode} from '../redux/CustomerPortalCRUD'
import {BookingModel} from '../../../models/ems/BookingModel'
import {CustomerModel} from '../../../models/CustomerModel'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {EventModel} from '../../../models/ems/EventModel'

interface BookingPageProps {
  customer?: CustomerModel
  event?: EventModel
}

export const BookingsPortalPage = ({customer, event}: BookingPageProps) => {
  const bookings = useRootStateSelector((state) => state.customerPortal.bookings)
  const [drawerCreate, setDrawerCreate] = useDrawerRef()
  const searchDebounce = useDebounce(200)
  const [drawerEdit, setDrawerEdit] = useDrawerRef()
  const [bookingToEdit, setBookingToEdit] = useState<BookingModel>()
  const {setFilter} = useEntityFilter('customer-portal-booking')
  const dispatch = useDispatch()

  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['draft', 'confirmed', 'fulfilled', 'active'],
        event: event?.code,
        customer: customer?.code,
      },
      groupBy: '',
    }

    return filters
  }, [customer?.code, event?.code])

  const refresh = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.bookings.search())
    })
  }, [dispatch, searchDebounce])

  const handleFilter = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refresh()
    },
    [setFilter, refresh]
  )

  const handleEdit = useCallback(
    async (booking) => {
      const response = await GetBookingByCode(booking.code)
      if (drawerEdit) {
        drawerEdit.show()
      }
      setBookingToEdit(response.data)
    },
    [drawerEdit]
  )

  return (
    <>
      {!customer && <PageTitle>Bookings</PageTitle>}

      <BookingTable
        initialFilters={initialFilters}
        onRefresh={refresh}
        onEdit={handleEdit}
        onNewBookingClick={drawerCreate?.show}
        className='d-none d-md-block'
        data={bookings}
        onFilter={handleFilter}
        event={event}
        customer={customer}
      />

      <BookingCardGrid
        initialFilters={initialFilters}
        className='d-md-none'
        data={bookings}
        onFilter={handleFilter}
      />

      <BookingPortalFormEdit
        event={event}
        customer={customer}
        bookingToEdit={bookingToEdit}
        drawerRef={setDrawerEdit}
        onChange={refresh}
      />
      <BookingPortalFormCreate
        customer={customer}
        event={event}
        drawerRef={setDrawerCreate}
        onChange={refresh}
      />
    </>
  )
}
