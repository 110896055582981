import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useDrawerRef} from '../../../components/Drawer/useDrawerRef'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {FilterModel} from '../../../models/FilterModel'
import {actions} from '../redux/CustomerPortalRedux'
import {CustomerModel} from '../../../models/CustomerModel'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {ReservationTable} from '../components/tables/ReservationTable/ReservationTable'
import {ReservationFormCreate} from '../components/DrawerForm/ReservationFormDrawer'
import {EventModel} from '../../../models/ems/EventModel'

interface ReservationPageProps {
  customer?: CustomerModel
  event?: EventModel
}
export const ReservationPage = ({customer, event}: ReservationPageProps) => {
  const reservations = useRootStateSelector((state) => state.customerPortal.reservations)
  const [drawerCreate, setDrawerCreate] = useDrawerRef()
  const searchDebounce = useDebounce(200)
  const {setFilter} = useEntityFilter('customer-portal-reservation')
  const dispatch = useDispatch()

  const refresh = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.reservations.search())
    })
  }, [dispatch, searchDebounce])

  const handleFilter = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refresh()
    },
    [setFilter, refresh]
  )

  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['active', 'released'],
      },
      groupBy: '',
    }
    if (event && filters.filters) {
      filters.filters.event = event.code
    }

    return filters
  }, [event])

  return (
    <>
      {!customer && <PageTitle>Reservations</PageTitle>}
      <ReservationTable
        onRefresh={refresh}
        onNewReservationClick={drawerCreate?.show}
        className='d-none d-md-block'
        data={reservations}
        onFilter={handleFilter}
        event={event}
        initialFilters={initialFilters}
      />

      <ReservationFormCreate drawerRef={setDrawerCreate} onChange={refresh} event={event} />
    </>
  )
}
