import {useCallback} from 'react'
import {SeatMapValue} from '../SeatMapValue'

export interface UseSeatHelperOptions {
  renderHidden?: boolean
  hidden?: SeatMapValue
  occupied?: SeatMapValue
  extra?: SeatMapValue
  shared?: SeatMapValue
  disabled?: SeatMapValue
  value?: SeatMapValue
}

export const useSeatHelper = ({
  disabled,
  extra,
  hidden,
  shared,
  occupied,
  renderHidden = false,
  value,
}: UseSeatHelperOptions) => {
  const shouldSeatRender = useCallback(
    (row: string, column: number): boolean => {
      if (hidden?.isValueExist(row, column)) {
        return renderHidden
      }
      return true
    },
    [hidden, renderHidden]
  )

  const isSeatOccupied = useCallback(
    (rowLetter: string, column: number) => {
      if (occupied) {
        return occupied.isValueExist(rowLetter, column)
      }
      return false
    },
    [occupied]
  )

  const isSeatExtra = useCallback(
    (rowLetter: string, column: number) => {
      if (extra) {
        return extra.isValueExist(rowLetter, column)
      }
      return false
    },
    [extra]
  )

  const isSeatShared = useCallback(
    (rowLetter: string, column: number) => {
      if (shared) {
        return shared.isValueExist(rowLetter, column)
      }
      return false
    },
    [shared]
  )

  const isSeatDisabled = useCallback(
    (rowLetter: string, column: number) => {
      if (disabled) {
        return disabled.isValueExist(rowLetter, column)
      }
      return false
    },
    [disabled]
  )

  const isSeatHidden = useCallback(
    (rowLetter: string, column: number) => {
      if (hidden) {
        return hidden.isValueExist(rowLetter, column)
      }
      return false
    },
    [hidden]
  )

  const getSeatColor = useCallback(
    (row: string, column: number): string => {
      if (value?.isValueExist(row, column)) {
        return ACTIVE_COLOR
      }
      if (isSeatExtra(row, column)) {
        return WARNING_COLOR
      }
      if (isSeatHidden(row, column)) {
        return BLACK_COLOR
      }
      if (isSeatDisabled(row, column)) {
        return DANGER_COLOR
      }
      if (isSeatOccupied(row, column)) {
        return DANGER_COLOR
      }
      if (isSeatShared(row, column)) {
        return PURPLE_COLOR
      }
      return DEFAULT_COLOR
    },
    [isSeatDisabled, isSeatExtra, isSeatHidden, isSeatOccupied, isSeatShared, value]
  )

  return {
    shouldSeatRender,
    isSeatOccupied,
    isSeatHidden,
    isSeatDisabled,
    isSeatExtra,
    getSeatColor,
  }
}

const ACTIVE_COLOR = '#0d6efd'
const DEFAULT_COLOR = '#333333'
const WARNING_COLOR = '#ffa800'
const DANGER_COLOR = '#f64e60'
const BLACK_COLOR = '#0b0b0c'
const PURPLE_COLOR = '#8950fc'
