import {Ref, useCallback, useImperativeHandle, useMemo} from 'react'
import clsx from 'clsx'
import {v4 as uuidv4} from 'uuid'
import {ListCountInputItem, ListCountInputItemValue} from './ListCountInputItem'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {FilterModel} from '../../../models/FilterModel'
import {SelectInputItem} from '../SelectInput'

export interface ListCountInputRefValue {
  addEmptyItem: () => void
  hasBlankItem: boolean
}

export interface ListCountInputProps<T> {
  className?: string
  searchResult?: GlobalSearchModel<T>
  onSearch: (filter: FilterModel) => void
  values: Array<ListCountInputItemValue<T>>
  onChange: (newValues: ListCountInputItemValue<T>[]) => void
  label: string
  disabled?: boolean
  itemMapper: (data: T) => SelectInputItem
  selectPlaceholder: string
  listRef?: Ref<ListCountInputRefValue> | null
}

export const ListCountInput = <T,>({
  className,
  onChange,
  searchResult,
  onSearch,
  values,
  label,
  disabled,
  itemMapper,
  selectPlaceholder,
  listRef,
}: ListCountInputProps<T>) => {
  const getInputId = useCallback(() => {
    return uuidv4()
  }, [])

  const handleOnAdd = useCallback(() => {
    const newValues: ListCountInputItemValue<T>[] = [
      ...values,
      {count: 0, data: null, id: getInputId()},
    ]
    onChange(newValues)
  }, [values, onChange, getInputId])

  const handleInputChange = useCallback(
    (changed: ListCountInputItemValue<T>) => {
      const inputIndex = values.findIndex((item) => item.id === changed.id)
      if (inputIndex >= 0) {
        const newValues = [...values]
        const modifiedItem = newValues[inputIndex]
        modifiedItem.count = changed.count
        modifiedItem.data = changed.data
        if (modifiedItem.data && modifiedItem.count === 0) {
          modifiedItem.count = 1
        }
        onChange(newValues)
      }
    },
    [values, onChange]
  )

  const handleRemove = useCallback(
    (removed: ListCountInputItemValue<T>) => {
      const inputIndex = values.findIndex((item) => item?.id === removed.id)
      if (inputIndex >= 0) {
        const newValues = [...values]
        newValues.splice(inputIndex, 1)
        onChange(newValues)
      }
    },
    [onChange, values]
  )

  const inputNodes = useMemo(() => {
    const nodes = values.map((item, i) => {
      return (
        <ListCountInputItem
          disabled={disabled}
          key={`${item.id}${i}`}
          onSearch={onSearch}
          placeholder={selectPlaceholder}
          onChange={handleInputChange}
          selectedItems={values}
          searchResult={searchResult}
          label={`${label} ${i + 1}`}
          onRemove={handleRemove}
          itemMapper={itemMapper}
          value={item}
        />
      )
    })
    return nodes
  }, [
    values,
    disabled,
    onSearch,
    selectPlaceholder,
    handleInputChange,
    searchResult,
    label,
    handleRemove,
    itemMapper,
  ])

  const hasBlankItem = useMemo(() => {
    return values.some((some) => some.data === null)
  }, [values])

  useImperativeHandle(listRef, () => ({hasBlankItem, addEmptyItem: handleOnAdd}), [
    handleOnAdd,
    hasBlankItem,
  ])

  return (
    <div className={clsx('', className)}>
      <div>{inputNodes}</div>
    </div>
  )
}
