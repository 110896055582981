import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {FilterModel} from '../../../../models/FilterModel'
import {OrderModel, OrderModelCreateParams} from '../../../../models/fnb/OrderModel'
import {OutletLogModel} from '../../../../models/fnb/OutletLogModel'
import {OutletModel, OutletModelCreateParams} from '../../../../models/fnb/OutletModel'
import {
  ProductCategoryModel,
  ProductCategoryModelCreateParams,
} from '../../../../models/fnb/ProductCategoryModel'
import {ProductModel, ProductModelCreateParams} from '../../../../models/fnb/ProductModel'
import {ReservationModel} from '../../../../models/fnb/ReservationModel'
import {UserModel, UserModelCreateParams} from '../../../../models/fnb/UserModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {
  GetOrders,
  GetOutletLogs,
  GetOutlets,
  GetProductCategories,
  GetProducts,
  GetUsers,
  SearchTableReservations,
} from './FnbCRUD'

interface ActionWithPayload<T> extends Action {
  payload: T
}

interface IPagesState {
  outlets?: GlobalSearchModel<OutletModel>
  products?: GlobalSearchModel<ProductModel>
  productCategories?: GlobalSearchModel<ProductCategoryModel>
  users?: GlobalSearchModel<UserModel>
  orders?: GlobalSearchModel<OrderModel>
  outletLogs?: GlobalSearchModel<OutletLogModel>
  reservations?: GlobalSearchModel<ReservationModel>
}

const initialAuthState: IPagesState = {
  outlets: undefined,
  products: undefined,
  productCategories: undefined,
  users: undefined,
  orders: undefined,
  outletLogs: undefined,
  reservations: undefined,
}

export const reducer = persistReducer(
  {storage, key: 'webapp-fnb', whitelist: []},
  (state: IPagesState = initialAuthState, action: Partial<ActionWithPayload<IPagesState>>) => {
    switch (action.type) {
      case actionTypes.outlets.search.success: {
        const outlets = action.payload?.outlets
        return {...state, outlets}
      }
      case actionTypes.products.search.success: {
        const products = action.payload?.products
        return {...state, products}
      }
      case actionTypes.productCategories.search.success: {
        const productCategories = action.payload?.productCategories
        return {...state, productCategories}
      }
      case actionTypes.users.search.success: {
        const users = action.payload?.users
        return {...state, users}
      }
      case actionTypes.orders.search.success: {
        const orders = action.payload?.orders
        return {...state, orders}
      }
      case actionTypes.outletLogs.search.success: {
        const outletLogs = action.payload?.outletLogs
        return {...state, outletLogs}
      }
      case actionTypes.reservations.search.success: {
        const reservations = action.payload?.reservations
        return {...state, reservations}
      }
      default:
        return state
    }
  }
)

export function* saga() {
  yield takeLatest(
    [
      actionTypes.outlets.search.execute,
      actionTypes.outlets.create.success,
      actionTypes.outlets.update.success,
      actionTypes.outlets.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select((state) => state.system.filters.outlet)
        const {data} = yield GetOutlets(filter)
        yield put(actions.outlets.searchSuccess(data))
      } catch (e) {
        yield put(actions.outlets.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.orders.search.execute,
      actionTypes.orders.create.success,
      actionTypes.orders.update.success,
      actionTypes.orders.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select((state) => state.system.filters['fnb-order'])
        const {data} = yield GetOrders(filter)
        yield put(actions.orders.searchSuccess(data))
      } catch (e) {
        yield put(actions.orders.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.productCategories.search.execute,
      actionTypes.productCategories.create.success,
      actionTypes.productCategories.update.success,
      actionTypes.productCategories.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select(
          (state) => state.system.filters['fnb-product-category']
        )
        const {data} = yield GetProductCategories(filter)
        yield put(actions.productCategories.searchSuccess(data))
      } catch (e) {
        yield put(actions.productCategories.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.products.search.execute,
      actionTypes.products.create.success,
      actionTypes.products.update.success,
      actionTypes.products.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select((state) => state.system.filters['fnb-product'])
        const {data} = yield GetProducts(filter)
        yield put(actions.products.searchSuccess(data))
      } catch (e) {
        yield put(actions.products.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.users.search.execute,
      actionTypes.users.create.success,
      actionTypes.users.update.success,
      actionTypes.users.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select((state) => state.system.filters['fnb-user'])
        const {data} = yield GetUsers(filter)
        yield put(actions.users.searchSuccess(data))
      } catch (e) {
        yield put(actions.users.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.outletLogs.search.execute,
      actionTypes.outletLogs.create.success,
      actionTypes.outletLogs.update.success,
      actionTypes.outletLogs.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select((state) => state.system.filters['fnb-outlet-log'])
        const {data} = yield GetOutletLogs(filter)
        yield put(actions.outletLogs.searchSuccess(data))
      } catch (e) {
        yield put(actions.outletLogs.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.reservations.search.execute,
      actionTypes.reservations.create.success,
      actionTypes.reservations.update.success,
      actionTypes.reservations.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select((state) => state.system.filters['fnb-reservation'])
        const {data} = yield SearchTableReservations(filter)
        yield put(actions.reservations.searchSuccess(data))
      } catch (e) {
        yield put(actions.reservations.searchFailed())
      }
    }
  )
}

export const actions = {
  outlets: {
    search: () => ({type: actionTypes.outlets.search.execute}),
    searchSuccess: (data: GlobalSearchModel<OutletModel>) => ({
      type: actionTypes.outlets.search.success,
      payload: {outlets: data},
    }),
    searchFailed: () => ({type: actionTypes.outlets.search.failed}),

    create: (payload: OutletModelCreateParams) => ({
      type: actionTypes.outlets.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.outlets.create.success}),
    createFailed: () => ({type: actionTypes.outlets.create.failed}),

    update: (payload: OutletModelCreateParams) => ({
      type: actionTypes.outlets.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.outlets.update.success}),
    updateFailed: () => ({type: actionTypes.outlets.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.outlets.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.outlets.delete.success}),
    deleteFailed: () => ({type: actionTypes.outlets.delete.failed}),
  },
  products: {
    search: () => ({type: actionTypes.products.search.execute}),
    searchSuccess: (data: GlobalSearchModel<ProductModel>) => ({
      type: actionTypes.products.search.success,
      payload: {products: data},
    }),
    searchFailed: () => ({type: actionTypes.products.search.failed}),

    create: (payload: ProductModelCreateParams) => ({
      type: actionTypes.products.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.products.create.success}),
    createFailed: () => ({type: actionTypes.products.create.failed}),

    update: (payload: ProductModelCreateParams) => ({
      type: actionTypes.products.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.products.update.success}),
    updateFailed: () => ({type: actionTypes.products.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.products.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.products.delete.success}),
    deleteFailed: () => ({type: actionTypes.products.delete.failed}),
  },
  productCategories: {
    search: () => ({type: actionTypes.productCategories.search.execute}),
    searchSuccess: (data: GlobalSearchModel<ProductCategoryModel>) => ({
      type: actionTypes.productCategories.search.success,
      payload: {productCategories: data},
    }),
    searchFailed: () => ({type: actionTypes.productCategories.search.failed}),

    create: (payload: ProductCategoryModelCreateParams) => ({
      type: actionTypes.productCategories.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.productCategories.create.success}),
    createFailed: () => ({type: actionTypes.productCategories.create.failed}),

    update: (payload: ProductCategoryModelCreateParams) => ({
      type: actionTypes.productCategories.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.productCategories.update.success}),
    updateFailed: () => ({type: actionTypes.productCategories.update.failed}),

    delete: (codes: string[]) => ({
      type: actionTypes.productCategories.delete.execute,
      payload: codes,
    }),
    deleteSuccess: () => ({type: actionTypes.productCategories.delete.success}),
    deleteFailed: () => ({type: actionTypes.productCategories.delete.failed}),
  },
  users: {
    search: () => ({type: actionTypes.users.search.execute}),
    searchSuccess: (data: GlobalSearchModel<UserModel>) => ({
      type: actionTypes.users.search.success,
      payload: {users: data},
    }),
    searchFailed: () => ({type: actionTypes.users.search.failed}),

    create: (payload: UserModelCreateParams) => ({
      type: actionTypes.users.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.users.create.success}),
    createFailed: () => ({type: actionTypes.users.create.failed}),

    update: (payload: UserModelCreateParams) => ({
      type: actionTypes.users.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.users.update.success}),
    updateFailed: () => ({type: actionTypes.users.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.users.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.users.delete.success}),
    deleteFailed: () => ({type: actionTypes.users.delete.failed}),
  },
  orders: {
    search: () => ({type: actionTypes.orders.search.execute}),
    searchSuccess: (data: GlobalSearchModel<OrderModel>) => ({
      type: actionTypes.orders.search.success,
      payload: {orders: data},
    }),
    searchFailed: () => ({type: actionTypes.orders.search.failed}),

    create: (payload: OrderModelCreateParams) => ({
      type: actionTypes.orders.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.orders.create.success}),
    createFailed: () => ({type: actionTypes.orders.create.failed}),

    update: (payload: OrderModelCreateParams) => ({
      type: actionTypes.orders.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.orders.update.success}),
    updateFailed: () => ({type: actionTypes.orders.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.orders.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.orders.delete.success}),
    deleteFailed: () => ({type: actionTypes.orders.delete.failed}),
  },
  reservations: {
    search: () => ({type: actionTypes.reservations.search.execute}),
    searchSuccess: (data: GlobalSearchModel<ReservationModel>) => ({
      type: actionTypes.reservations.search.success,
      payload: {reservations: data},
    }),
    searchFailed: () => ({type: actionTypes.reservations.search.failed}),

    update: (payload: OrderModelCreateParams) => ({
      type: actionTypes.reservations.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.reservations.update.success}),
    updateFailed: () => ({type: actionTypes.reservations.update.failed}),
  },
  outletLogs: {
    search: () => ({type: actionTypes.outletLogs.search.execute}),
    searchSuccess: (data: GlobalSearchModel<OutletModel>) => ({
      type: actionTypes.outletLogs.search.success,
      payload: {outletLogs: data},
    }),
    searchFailed: () => ({type: actionTypes.outletLogs.search.failed}),
  },
}

const actionTypes = {
  outlets: {
    search: {
      execute: '[FNB] SEARCH OUTLET',
      success: '[FNB] SEARCH OUTLET SUCCESS',
      failed: '[FNB] SEARCH OUTLET FAILED',
    },
    create: {
      execute: '[FNB] CREATE OUTLET',
      success: '[FNB] CREATE OUTLET SUCCESS',
      failed: '[FNB] CREATE OUTLET FAILED',
    },
    update: {
      execute: '[FNB] SEARCH OUTLET',
      success: '[FNB] UPDATE OUTLET SUCCESS',
      failed: '[FNB] UPDATE OUTLET FAILED',
    },
    delete: {
      execute: '[FNB] DELETE OUTLET',
      success: '[FNB] DELETE OUTLET SUCCESS',
      failed: '[FNB] DELETE OUTLET FAILED',
    },
  },
  products: {
    search: {
      execute: '[FNB] SEARCH PRODUCT',
      success: '[FNB] SEARCH PRODUCT SUCCESS',
      failed: '[FNB] SEARCH PRODUCT FAILED',
    },
    create: {
      execute: '[FNB] CREATE PRODUCT',
      success: '[FNB] CREATE PRODUCT SUCCESS',
      failed: '[FNB] CREATE PRODUCT FAILED',
    },
    update: {
      execute: '[FNB] UPDATE PRODUCT',
      success: '[FNB] UPDATE PRODUCT SUCCESS',
      failed: '[FNB] UPDATE PRODUCT FAILED',
    },
    delete: {
      execute: '[FNB] DELETE PRODUCT',
      success: '[FNB] DELETE PRODUCT SUCCESS',
      failed: '[FNB] DELETE PRODUCT FAILED',
    },
  },
  productCategories: {
    search: {
      execute: '[FNB] SEARCH PRODUCT CATEGORY',
      success: '[FNB] SEARCH PRODUCT CATEGORY SUCCESS',
      failed: '[FNB] SEARCH PRODUCT CATEGORY FAILED',
    },
    create: {
      execute: '[FNB] CREATE PRODUCT CATEGORY',
      success: '[FNB] CREATE PRODUCT CATEGORY SUCCESS',
      failed: '[FNB] CREATE PRODUCT CATEGORY FAILED',
    },
    update: {
      execute: '[FNB] UPDATE PRODUCT CATEGORY',
      success: '[FNB] UPDATE PRODUCT CATEGORY SUCCESS',
      failed: '[FNB] UPDATE PRODUCT CATEGORY FAILED',
    },
    delete: {
      execute: '[FNB] DELETE PRODUCT CATEGORY',
      success: '[FNB] DELETE PRODUCT CATEGORY SUCCESS',
      failed: '[FNB] DELETE PRODUCT CATEGORY FAILED',
    },
  },
  users: {
    search: {
      execute: '[FNB] SEARCH USER',
      success: '[FNB] SEARCH USER SUCCESS',
      failed: '[FNB] SEARCH USER FAILED',
    },
    create: {
      execute: '[FNB] CREATE USER',
      success: '[FNB] CREATE USER SUCCESS',
      failed: '[FNB] CREATE USER FAILED',
    },
    update: {
      execute: '[FNB] UPDATE USER',
      success: '[FNB] UPDATE USER SUCCESS',
      failed: '[FNB] UPDATE USER FAILED',
    },
    delete: {
      execute: '[FNB] DELETE USER',
      success: '[FNB] DELETE USER SUCCESS',
      failed: '[FNB] DELETE USER FAILED',
    },
  },
  orders: {
    search: {
      execute: '[FNB] SEARCH ORDER',
      success: '[FNB] SEARCH ORDER SUCCESS',
      failed: '[FNB] SEARCH ORDER FAILED',
    },
    create: {
      execute: '[FNB] CREATE ORDER',
      success: '[FNB] CREATE ORDER SUCCESS',
      failed: '[FNB] CREATE ORDER FAILED',
    },
    update: {
      execute: '[FNB] UPDATE ORDER',
      success: '[FNB] UPDATE ORDER SUCCESS',
      failed: '[FNB] UPDATE ORDER FAILED',
    },
    delete: {
      execute: '[FNB] DELETE ORDER',
      success: '[FNB] DELETE ORDER SUCCESS',
      failed: '[FNB] DELETE ORDER FAILED',
    },
  },
  reservations: {
    search: {
      execute: '[FNB] SEARCH TABLE RESERVATION',
      success: '[FNB] SEARCH TABLE RESERVATION SUCCESS',
      failed: '[FNB] SEARCH TABLE RESERVATION FAILED',
    },
    create: {
      execute: '[FNB] CREATE TABLE RESERVATION',
      success: '[FNB] CREATE TABLE RESERVATION SUCCESS',
      failed: '[FNB] CREATE TABLE RESERVATION FAILED',
    },
    update: {
      execute: '[FNB] UPDATE TABLE RESERVATION',
      success: '[FNB] UPDATE TABLE RESERVATION SUCCESS',
      failed: '[FNB] UPDATE TABLE RESERVATION FAILED',
    },
    delete: {
      execute: '[FNB] DELETE TABLE RESERVATION',
      success: '[FNB] DELETE TABLE RESERVATION SUCCESS',
      failed: '[FNB] DELETE TABLE RESERVATION FAILED',
    },
  },
  outletLogs: {
    search: {
      execute: '[FNB] SEARCH OUTLET LOG',
      success: '[FNB] SEARCH OUTLET LOG SUCCESS',
      failed: '[FNB] SEARCH OUTLET LOG FAILED',
    },
    create: {
      execute: '[FNB] CREATE OUTLET LOG',
      success: '[FNB] CREATE OUTLET LOG SUCCESS',
      failed: '[FNB] CREATE OUTLET LOG FAILED',
    },
    update: {
      execute: '[FNB] UPDATE OUTLET LOG',
      success: '[FNB] UPDATE OUTLET LOG SUCCESS',
      failed: '[FNB] UPDATE OUTLET LOG FAILED',
    },
    delete: {
      execute: '[FNB] DELETE OUTLET LOG',
      success: '[FNB] DELETE OUTLET LOG SUCCESS',
      failed: '[FNB] DELETE OUTLET LOG FAILED',
    },
  },
}
