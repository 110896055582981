import {LAYOUT_TYPE} from '../../config/env'
import {Routes as DefaultRoutes} from './default/Routes'
import {Routes as OutletRoutes} from './outlet/Routes'
import {Routes as DigitalMenuRoutes} from './digital-menu/Routes'
import {Routes as CustomerPortalRoutes} from './customer-portal/Routes'
import {Routes as CustomerDelegatesRoutes} from './customer-delegate/Routes'
import {Routes as CustomerRegistrationRoutes} from './customer-registration/Routes'
import {Routes as EVARoutes} from './eva/Routes'
import {Routes as PokenRoutes} from './poken/Routes'

export const Routes = () => {
  switch (LAYOUT_TYPE) {
    case 'outlet':
      return <OutletRoutes />
    case 'customer-delegate':
      return <CustomerDelegatesRoutes />
    case 'customer-portal':
      return <CustomerPortalRoutes />
    case 'customer-registration':
      return <CustomerRegistrationRoutes />
    case 'outlet-digital-menu':
      return <DigitalMenuRoutes />
    case 'eva':
      return <EVARoutes />
    case 'poken':
      return <PokenRoutes />
    case 'default':
      return <DefaultRoutes />
    default:
      throw new Error(`Unknown layout type ${LAYOUT_TYPE}`)
  }
}
