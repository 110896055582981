import {FormikContextType} from 'formik'
import {useCallback} from 'react'
import {useSeatMapState} from '../../../../components/hooks/useSeatMapState'
import {SeatMapSelectionModalInput} from './SeatMapSelectionModalInput'
import {SeatMapValueObject} from '../../../../components/inputs/SeatMapInput/SeatMapValue'
import {useModalState} from '../../../../components/modals/useModalState'
import {TreeNodeItem} from '../../../../components/inputs/TreeSelect/TreeSelect'
import {ReservationFormValues} from '../DrawerForm/ReservationFormDrawer/ReservationForm'
import {EventModel} from '../../../../models/ems/EventModel'

export interface ReservationSeatMapValues {
  locationCode: string
  row?: string
  column?: number
}

export interface ReservationSeatMapProps {
  open: boolean
  locationItems: TreeNodeItem[]
  locationvalue: string[]
  onClose: () => void
  formik: FormikContextType<ReservationFormValues>
  event?: EventModel
}

export const ReservationSeatMap = ({
  onClose,
  open,
  locationItems,
  locationvalue,
  formik,
  event,
}: ReservationSeatMapProps) => {
  const {hide: hideSelectionModal} = useModalState()
  const {
    seatMapSpacingX,
    occupied,
    extra,
    disabled,
    columns,
    rows,
    hidden,
    isBottomToTop,
    isRightToLeft,
    isLoading,
  } = useSeatMapState()

  const handleSelectionModalHide = useCallback(() => {
    hideSelectionModal()
    onClose()
  }, [hideSelectionModal, onClose])

  const handleSeats = useCallback(
    (seats: SeatMapValueObject) => {
      formik.setFieldValue('locations', [
        {
          code: locationvalue[0],
          details: seats,
        },
      ])
    },
    [locationvalue, formik]
  )

  return (
    <>
      <SeatMapSelectionModalInput
        event={event}
        locationItems={locationItems}
        locationvalue={locationvalue}
        open={open}
        spacingX={seatMapSpacingX}
        occupied={occupied}
        loading={isLoading}
        disableSubmit={formik.isSubmitting}
        disableSelection={formik.isSubmitting}
        extra={extra}
        count={1}
        disabled={disabled}
        columns={columns}
        rows={rows}
        hidden={hidden}
        onHide={handleSelectionModalHide}
        isRightToLeft={isRightToLeft}
        isBottomToTop={isBottomToTop}
        handleSeats={handleSeats}
      />
    </>
  )
}
