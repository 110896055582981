import {useMemo} from 'react'
import {Logo403TedxSolaax} from '../../../config/logos'
import styles from './FullPage403.module.scss'

export interface FullPage403Props {
  link?: string
}

export const FullPage403 = ({link}: FullPage403Props) => {
  const image = useMemo(() => {
    return (
      <a target='_blank' rel='noreferrer' href='https://solaax.com'>
        <img className={styles.logo} src={Logo403TedxSolaax.src} alt={Logo403TedxSolaax.alt} />
      </a>
    )
  }, [])

  return (
    <div className={styles.root}>
      <div className='h-100 d-flex flex-column align-items-center gap-5 justify-content-center'>
        {image}
        <p className='text-center fs-4'>
          <span>
            You have already responded to this RSVP. For further support please contact us at{' '}
          </span>
          <span>
            <a href='mailto:tedx@solaax.com'>tedx@solaax.com</a>
          </span>
        </p>
      </div>
    </div>
  )
}
