import {useCallback, useMemo, useState} from 'react'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {useSeatMapPortalReservationState} from '../../hooks/useSeatMapPortalReservationState'
import {SeatMapSelectionInput} from './SeatMapSelectionInput'
import {
  SeatMapValue,
  SeatMapValueObject,
} from '../../../../components/inputs/SeatMapInput/SeatMapValue'
import {GetAvailablReservationSeatMap, GetSeatsByLocationCode} from '../../redux/CustomerPortalCRUD'
import {useCustomerAuth} from '../../hooks/useCustomerAuth'
import {TreeNodeItem} from '../../../../components/inputs/TreeSelect/TreeSelect'
import {EventModel} from '../../../../models/ems/EventModel'
interface ReservationDetailSeatMapsProps {
  locationItems: TreeNodeItem[]
  locationvalue: string[]
  onHide: () => void
  handleSeats: (seats: SeatMapValueObject) => void
  event?: EventModel
}
export const ReservationDetailSeatMaps = ({
  locationItems,
  locationvalue,
  onHide,
  handleSeats,
  event,
}: ReservationDetailSeatMapsProps) => {
  const [selectedLocation, setSelectedLocation] = useState(locationvalue[0])
  const [selected, setSelected] = useState<SeatMapValue>()

  const auth = useCustomerAuth()
  const {
    resetState,
    columns,
    rows,
    extra,
    disabled,
    shared,
    active,
    hidden,
    isRightToLeft,
    isBottomToTop,
    setAvailableSeates,
  } = useSeatMapPortalReservationState()
  const {push} = useAlerts()

  const customerCode = useMemo(() => {
    return auth?.getUser().code
  }, [auth])

  const getSeats = useCallback(async () => {
    try {
      if (customerCode && event) {
        const {
          data: {availableSeats},
        } = await GetAvailablReservationSeatMap(selectedLocation, event.code)

        setAvailableSeates(new SeatMapValue(availableSeats))
      }
    } catch (e) {
      push({message: 'No seats availabile', variant: 'danger', timeout: 5000})
    }
  }, [customerCode, event, push, selectedLocation, setAvailableSeates])

  const getSeatsByLocation = useCallback(async () => {
    const {data: currentLocation} = await GetSeatsByLocationCode(selectedLocation)
    if (currentLocation.seatMap) {
      resetState(currentLocation.seatMap)
      getSeats()
    } else {
      push({message: `${currentLocation.name} has no seats.`, variant: 'danger', timeout: 5000})
    }
  }, [getSeats, push, resetState, selectedLocation])

  const inputValues = useMemo(() => {
    return {selectedLocation}
  }, [selectedLocation])

  useOnChange(inputValues, async () => {
    resetState()
    if (selectedLocation && customerCode) {
      getSeatsByLocation()
    }
  })

  useOnChange(inputValues, async () => {
    resetState()
    if (selectedLocation && customerCode) {
      getSeats()
    }
  })

  const occupied = useMemo(() => {
    let activeSeats = new SeatMapValue()
    if (extra) {
      activeSeats = activeSeats.union(extra)
    }
    if (active) {
      activeSeats = activeSeats.union(active)
    }
    if (shared) {
      activeSeats = activeSeats.union(shared)
    }
    return new SeatMapValue(rows, columns).difference(activeSeats)
  }, [active, columns, extra, rows, shared])

  const handleReserveSeat = useCallback(async () => {
    if (selected?.getSeatMapObject()) {
      onHide()
      handleSeats(selected?.getSeatMapObject())
    }
  }, [handleSeats, onHide, selected])

  return (
    <SeatMapSelectionInput
      locationItems={locationItems}
      locationCode={selectedLocation}
      onLocationChange={setSelectedLocation}
      onSelectedChange={setSelected}
      selected={selected}
      columns={columns}
      rows={rows}
      occupied={occupied}
      shared={shared}
      extra={extra}
      disabled={disabled}
      hidden={hidden}
      isRightToLeft={isRightToLeft}
      isBottomToTop={isBottomToTop}
      onReserveSeat={handleReserveSeat}
      onHide={onHide}
    />
  )
}
