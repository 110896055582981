import {useCallback, useMemo, useState} from 'react'
import {SeatMapModel} from '../../models/SeatMapModel'
import {
  GetOccupiedSeatMapsByProduct,
  GetOccupiedSeatMapsByReservation,
} from '../../modules/default/acs/redux/AcsCRUD'
import {SeatMapSpacing} from '../inputs/SeatMapInput/SeatMapSpacing'
import {SeatMapValue} from '../inputs/SeatMapInput/SeatMapValue'
import {useLoadingState} from './useLoadingState'
import {useSafeStateUpdate} from './useSafeStateUpdate'

export const useSeatMapState = () => {
  const [isRightToLeft, setIsRightToLeft] = useState(false)
  const [isBottomToTop, setIsBottomToTop] = useState(false)
  const [rows, setRows] = useState(0)
  const [columns, setColumns] = useState(0)
  const [disabled, setDisabled] = useState(new SeatMapValue())
  const [occupied, setOccupied] = useState(new SeatMapValue())
  const [extra, setExtra] = useState(new SeatMapValue())
  const [hidden, setHidden] = useState(new SeatMapValue())
  const [seatMapSpacingX, setSeatMapSpacingX] = useState(new SeatMapSpacing())
  const {isLoading, setIsLoading} = useLoadingState()
  const safeUpdate = useSafeStateUpdate()

  const resetState = useCallback((seatMap?: SeatMapModel) => {
    if (seatMap) {
      const {col, disabled, hidden, row, spacing, isRtlCol, isRtlRow} = seatMap
      if (spacing?.x) {
        const seatMapSpacing = new SeatMapSpacing(spacing.x)
        setSeatMapSpacingX(seatMapSpacing)
      }
      setIsRightToLeft(isRtlCol || false)
      setIsBottomToTop(isRtlRow || false)
      setColumns(parseInt(col as string))
      setRows(parseInt(row as string))
      setDisabled(new SeatMapValue(disabled))
      setHidden(new SeatMapValue(hidden))
    } else {
      setIsRightToLeft(false)
      setIsBottomToTop(false)
      setColumns(0)
      setRows(0)
      setDisabled(new SeatMapValue())
      setHidden(new SeatMapValue())
      setOccupied(new SeatMapValue())
      setExtra(new SeatMapValue())
      setSeatMapSpacingX(new SeatMapSpacing())
    }
  }, [])

  const setOccupiedWithSocialDistancing = useCallback(
    (occupied: SeatMapValue) => {
      let extra = occupied.clone()
      extra.applyOccupiedDistancing(SEAT_SOCIAL_DISTANCE)
      extra = extra.difference(occupied)
      safeUpdate(() => {
        setOccupied(occupied)
        setExtra(extra)
      })
    },
    [safeUpdate]
  )
  const setOccupiedByReservation = useCallback(
    (occupied: SeatMapValue) => {
      safeUpdate(() => {
        setOccupied(occupied)
      })
    },
    [safeUpdate]
  )

  const resetOccupiedData = useCallback(
    async (locationCode: string, eventCode: string, productCode: string) => {
      const doneLoading = setIsLoading([locationCode, eventCode, productCode])
      try {
        const {data} = await GetOccupiedSeatMapsByProduct(locationCode, eventCode, productCode)
        const occupied = new SeatMapValue(data)
        setOccupiedWithSocialDistancing(occupied)
      } catch (e) {
        /**
         * Seat has no bookings yet
         * */
      } finally {
        doneLoading()
      }
    },
    [setIsLoading, setOccupiedWithSocialDistancing]
  )

  const resetOccupiedByReservationData = useCallback(
    async (locationCode: string, eventCode: string) => {
      const doneLoading = setIsLoading([eventCode])
      const payload = {eventCode: eventCode}
      try {
        const {data} = await GetOccupiedSeatMapsByReservation(locationCode, payload)
        const occupied = new SeatMapValue(data)
        setOccupied(occupied)
      } catch (e) {
        /**
         * Seat has no bookings yet
         * */
      } finally {
        doneLoading()
      }
    },
    [setIsLoading]
  )

  return useMemo(() => {
    return {
      isLoading,
      setIsLoading,
      isRightToLeft,
      setIsRightToLeft,
      isBottomToTop,
      setIsBottomToTop,
      rows,
      setRows,
      columns,
      setColumns,
      disabled,
      setDisabled,
      occupied,
      setOccupied,
      extra,
      setExtra,
      hidden,
      setHidden,
      seatMapSpacingX,
      setSeatMapSpacingX,
      resetState,
      resetOccupiedData,
      resetOccupiedByReservationData,
      setOccupiedByReservation,
      setOccupiedWithSocialDistancing,
    }
  }, [
    columns,
    setIsLoading,
    disabled,
    extra,
    hidden,
    isBottomToTop,
    isLoading,
    isRightToLeft,
    occupied,
    resetOccupiedData,
    resetOccupiedByReservationData,
    resetState,
    rows,
    setOccupiedByReservation,
    seatMapSpacingX,
    setOccupiedWithSocialDistancing,
  ])
}

const SEAT_SOCIAL_DISTANCE = process.env.REACT_APP_SEAT_SOCIAL_DISTANCE
  ? parseInt(process.env.REACT_APP_SEAT_SOCIAL_DISTANCE)
  : 0
