export const ThankyouPage = () => {
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 min-h-100vh'>
        <a target='_blank' rel='noreferrer' href='https://solaax.com'>
          <img
            alt='tedx'
            src='/media/saas/tedx-solaax-1.png'
            className='img-fluid w-500px w-100 mb-8'
          />
        </a>

        <div
          className='w-sm-500px w-300px rounded shadow-sm p-10 p-lg-15 mx-auto text-center'
          style={{
            background: '#191a1d',
          }}
        >
          <h4>Thank you!</h4>
        </div>

        <div className='d-flex gap-7 mt-10'>
          <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/tedxeindhoven'>
            <img alt='tedx linkedin' src='/media/svg/social-logos/tedx/linkedin.png' width='30px' />
          </a>

          <a target='_blank' rel='noreferrer' href='https://twitter.com/tedxeindhoven'>
            <img alt='tedx twitter' src='/media/svg/social-logos/tedx/twitter.png' width='30px' />
          </a>

          <a target='_blank' rel='noreferrer' href='https://www.instagram.com/tedxeindhoven'>
            <img
              alt='tedx instagram'
              src='/media/svg/social-logos/tedx/instagram.png'
              width='30px'
            />
          </a>
        </div>
        <a target='_blank' rel='noreferrer' href='https://solaax.com'>
          <img
            alt='powered by solaax'
            src='/media/saas/powered-by-solaax.png'
            className='h-50px mt-12'
          />
        </a>
      </div>
    </div>
  )
}
