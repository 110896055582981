import {FormikContextType} from 'formik'
import {useCallback, useState} from 'react'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {SelectInput, TextInput} from '../../../../../components/inputs'
import {Button} from '../../../../../components/inputs/Button'
import {SelectInputItem} from '../../../../../components/inputs/SelectInput'
import {OrDivider} from '../../../../../components/utils/OrDivider'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {useFormDrawerCustomerData} from '../../../hooks/useFormDrawerCustomerData'
import {GetCustomerByEmailAndType} from '../../../redux/CustomerPortalCRUD'
import {CustomerFormValues} from './CustomerForm'

interface ExistCustomerSearchProps {
  customerTypeList: SelectInputItem[]
  formik: FormikContextType<CustomerFormValues>
  setIsExistedCustomer: ((customer?: CustomerModel) => void) | undefined
}

export const ExistCustomerSearchForm = ({
  customerTypeList,
  formik,
  setIsExistedCustomer,
}: ExistCustomerSearchProps) => {
  const [customerEmail, setCustomerEmail] = useState<string>('')
  const [customerType, setCustomerType] = useState<string>('customer')
  const formDrawer = useFormDrawerCustomerData()
  const {pushError} = useAlerts()
  const handleSearchCustomer = useCallback(async () => {
    if (customerEmail) {
      try {
        const {data} = await GetCustomerByEmailAndType({
          email: customerEmail,
          type: customerType,
        })
        if (data) {
          const values = formDrawer.mapCustomerDataToFormValues(data)
          formik.setValues(values)
          setIsExistedCustomer && setIsExistedCustomer(data)
        }
      } catch (err) {
        pushError(err)
      }
    }
  }, [customerEmail, customerType, formDrawer, formik, setIsExistedCustomer, pushError])

  return (
    <div className='mb-5'>
      <TextInput
        label='Customer Email'
        placeholder='Search Customer Email'
        value={customerEmail}
        onChange={(event) => setCustomerEmail(event.target.value)}
      />
      <SelectInput
        placeholder='Select Type'
        label='Type'
        items={customerTypeList}
        value={customerType}
        onChange={(event) => setCustomerType(event.target.value)}
      />
      <div className='d-flex gap-5 justify-content-end'>
        <div>
          <Button
            type='button'
            variant='text'
            onClick={() => {
              formik.resetForm()
              setIsExistedCustomer && setIsExistedCustomer()
            }}
            fullWidth
          >
            Clear
          </Button>
        </div>
        <div>
          <Button
            disabled={!customerEmail}
            type='button'
            variant='primary'
            onClick={handleSearchCustomer}
            fullWidth
          >
            Search
          </Button>
        </div>
      </div>
      <OrDivider className='mt-4' />
    </div>
  )
}
