import clsx from 'clsx'
import {ReservationDetailsModel} from '../../../../../models/customer-portal/ReservationDetalsModel'

export const ReservationDetailsStatusColumn = ({data}: {data: ReservationDetailsModel}) => {
  return <span className={getBadge(data)}>{data.reservationModuleStatus}</span>
}

const getBadge = (data: ReservationDetailsModel) => {
  let baseClass = 'badge text-uppercase'

  if (data) {
    switch (data.reservationModuleStatus) {
      case 'active':
        return clsx(baseClass, 'badge-light-primary')
      case 'closed':
        return clsx(baseClass, 'badge-light-danger')
      case 'released':
        return clsx(baseClass, 'badge-light-warning')
      default:
        return clsx(baseClass, 'badge-light-dark')
    }
  }
}
