import {useCallback, useMemo, useState} from 'react'
import {EventModel} from '../../../../models/ems/EventModel'
import {Redirect, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom'
import {PaneContainer} from '../../../../components/layouts/resizeable-panes/PaneContainer/PaneContainer'
import {Pane} from '../../../../components/layouts/resizeable-panes/Pane/Pane'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {FilterModel} from '../../../../models/FilterModel'
import {actions} from '../../redux/CustomerPortalRedux'
import {GetReservationByCode} from '../../redux/CustomerPortalCRUD'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {RootState} from '../../../../../setup'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import PortalHeader, {LinkType} from '../../components/Headers/PortalHeader'
import {ReservationDetailsTable} from '../../components/tables/ReservationDetailsTable/ReservationDetailsTable'
import {ReservationDetailsModel} from '../../../../models/customer-portal/ReservationDetalsModel'
import {ReservationPortalModel} from '../../../../models/ems/ReservationMedel'
import {ReservationDetailsHeader} from '../../components/ReservationDetailsHeader'
import {ReservationTable} from '../../components/tables/ReservationTable/ReservationTable'
import {ReservationFormCreate} from '../../components/DrawerForm/ReservationFormDrawer'
import {useDrawerRef} from '../../../../components/Drawer/useDrawerRef'

export interface EventPortalDetailReservationProps {
  event: EventModel | null
}

interface RouteMatch {
  reservationCode?: string
  customerCode?: string
  eventCode?: string
}
const EventPortalDetailReservation = ({event}: EventPortalDetailReservationProps) => {
  const match = useRouteMatch<RouteMatch>()
  const [drawerCreate, setDrawerCreate] = useDrawerRef()
  const {reservationCode, customerCode} = match.params
  const history = useHistory()
  const breakpoints = useBreakpoint()
  const isMobile = useMemo(() => breakpoints.down('md'), [breakpoints])
  const reservations = useRootStateSelector((state) => state.customerPortal.reservations)
  const [currentReservation, setCurrentReservation] = useState<ReservationPortalModel>()
  const searchDebounce = useDebounce(400)
  const {setFilter} = useEntityFilter('customer-portal-reservation')
  const {setFilter: setReservationDetailsFilter} = useEntityFilter(
    'customer-portal-reservation-details'
  )
  const dispatch = useDispatch()

  interface LinksTypes {
    [key: string]: LinkType[]
  }

  const _reservationDetails: GlobalSearchModel<ReservationDetailsModel> = useSelector<RootState>(
    ({customerPortal}) => customerPortal.reservationDetails,
    shallowEqual
  ) as GlobalSearchModel<ReservationDetailsModel>

  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['active', 'released'],
      },
      groupBy: '',
    }
    if (event && filters.filters) {
      filters.filters.event = event.code
    }

    return filters
  }, [event])

  const links: LinksTypes = useMemo(() => {
    let reservation = [
      {
        title: 'Reservation details',
        to: `/events/${event?.code}/reservation/customer/${customerCode}/reservation-details/${reservationCode}`,
      },
    ]
    return {
      reservation,
    }
  }, [customerCode, event?.code, reservationCode])

  const refreshCurrentReservationDetails = useCallback(async () => {
    if (reservationCode) {
      const {data} = await GetReservationByCode(reservationCode)
      setCurrentReservation(data)
    }
  }, [reservationCode])

  const refreshReservationTable = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.reservations.search())
    })
  }, [dispatch, searchDebounce])

  useOnChange(reservationCode, () => {
    if (reservationCode) {
      refreshCurrentReservationDetails()
    }
  })

  const onFilterReservationDetailsHandler = useCallback(
    (filter: FilterModel) => {
      setReservationDetailsFilter({
        ...filter,
        filters: {...filter.filters, reservation: reservationCode},
      })
      dispatch(actions.reservationDetails.search())
    },
    [setReservationDetailsFilter, reservationCode, dispatch]
  )

  const handleFilterReservationTable = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refreshReservationTable()
    },
    [setFilter, refreshReservationTable]
  )

  const handleSplitPaneClose = useCallback(() => {
    if (event) history.push(`/events/${event.code}/reservation`)
  }, [event, history])

  return (
    <>
      {event && (
        <>
          <ReservationFormCreate
            drawerRef={setDrawerCreate}
            onChange={refreshReservationTable}
            event={event}
          />
        </>
      )}
      <Switch>
        <Route path={`${match.path}`}>
          <PaneContainer className='flex-grow-1' direction='horizontal'>
            <Pane minimumWidth={isMobile ? undefined : 200} flexBasis={isMobile ? '0%' : '35%'}>
              {event && (
                <>
                  <div className='d-none d-md-block'>
                    <ReservationTable
                      onRefresh={refreshReservationTable}
                      onRefreshCallback={refreshCurrentReservationDetails}
                      data={reservations}
                      onFilter={handleFilterReservationTable}
                      event={event}
                      initialFilters={initialFilters}
                      onNewReservationClick={drawerCreate?.show}
                    />
                  </div>
                </>
              )}
            </Pane>
            <Pane minimumWidth={isMobile ? undefined : 200} flexBasis={isMobile ? '100%' : '65%'}>
              <MetronicIconButton
                size='sm'
                iconType='Navigation'
                iconName='Arrow-from-left'
                onClick={handleSplitPaneClose}
                variant='primary'
                tooltip='Close Pane'
                className='mb-1'
              />
              <>
                <ReservationDetailsHeader
                  customer={currentReservation?.customer}
                  reservation={currentReservation}
                  onRefresh={refreshCurrentReservationDetails}
                  onRefreshCallback={refreshReservationTable}
                />
                <PortalHeader links={links.reservation} />
                <ReservationDetailsTable
                  data={_reservationDetails}
                  onFilter={onFilterReservationDetailsHandler}
                  reservationCode={reservationCode}
                />
              </>
            </Pane>
          </PaneContainer>
        </Route>
        <Redirect to={match.path} />
      </Switch>
    </>
  )
}

export default EventPortalDetailReservation
