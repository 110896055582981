import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Redirect} from 'react-router-dom'
import * as OutletRedux from '../../modules/outlet/redux/OutletRedux'
import * as GlobalAuthRedux from '../../modules/default/auth/redux/AuthRedux'
import * as CustomerPortalRedux from '../../modules/customer-portal/redux/CustomerPortalRedux'
import * as PokenRedux from '../../modules/poken/redux/PokenRedux'
import {LAYOUT_TYPE} from '../../../config/env'

export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    switch (LAYOUT_TYPE) {
      case 'outlet':
        dispatch(OutletRedux.actions.auth.logout())
        break
      case 'customer-delegate':
      case 'customer-portal':
        dispatch(CustomerPortalRedux.actions.auth.logout())
        break

      case 'poken':
        dispatch(PokenRedux.actions.auth.logout())
        break
      default:
        dispatch(GlobalAuthRedux.actions.logout())
        break
    }

    document.location.reload()
  }, [dispatch])

  return <Redirect to='/auth/login' />
}
