import {useMemo} from 'react'
import {DateFormatter} from '../../../../../../components/utils/formatter/DateFormatter'
import {DateTimeFormatter} from '../../../../../../components/utils/formatter/DateTimeFormatter'
import {DateUtil} from '../../../../../../utils/DateUtil'

export interface TimeSlotFormatterProps {
  isTimeslot?: boolean
  children: Date | string
  className?: string
}

export const TimeSlotFormatter = ({isTimeslot, children, className}: TimeSlotFormatterProps) => {
  const parsedDate = useMemo(() => {
    if (children instanceof Date) {
      return children
    }
    return DateUtil.getDateFromApiString(children)
  }, [children])

  if (isTimeslot) {
    return <DateTimeFormatter className={className}>{parsedDate}</DateTimeFormatter>
  }
  return <DateFormatter className={className}>{parsedDate}</DateFormatter>
}
