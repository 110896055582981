import clsx from 'clsx'
import {useCallback, useMemo} from 'react'
import {TreeNodeItem} from '../../../../components/inputs/TreeSelect/TreeSelect'
import {
  SeatMapEditorInput,
  SeatMapEditorInputProps,
} from '../../../../components/inputs/SeatMapInput/SeatMapEditorInput'
import {SeatMapValue} from '../../../../components/inputs/SeatMapInput/SeatMapValue'
import {Button} from '../../../../components/inputs/Button'
export interface SeatMapSelectionInputProps extends SeatMapEditorInputProps {
  locationItems?: TreeNodeItem[]
  onLocationChange?: (value: string) => void
  onReserveSeat?: () => void
  locationCode?: string
  onHide?: () => void
}

export const SeatMapSelectionInput = ({
  className,
  locationItems,
  onLocationChange,
  locationCode,
  onSelectedChange,
  onReserveSeat,
  disabled,
  selected,
  shared,
  occupied,
  onHide,
  ...props
}: SeatMapSelectionInputProps) => {
  const handleSelectSeates = useCallback(
    (value: SeatMapValue) => {
      let finalValue = value
      if (onSelectedChange) {
        if (disabled) {
          finalValue = finalValue.difference(disabled)
        }
        if (occupied) {
          finalValue = finalValue.difference(occupied)
        }
        if (shared) {
          finalValue = finalValue.difference(shared)
        }

        onSelectedChange(finalValue)
      }
    },
    [disabled, occupied, onSelectedChange, shared]
  )

  const actionButtons = useMemo(() => {
    return (
      <>
        <Button type='button' variant='text' onClick={onHide}>
          Cancel
        </Button>
        <Button
          type='button'
          variant='primary'
          disabled={selected && Object.keys(selected.getSeatMapObject()).length ? false : true}
          onClick={onReserveSeat}
        >
          Reserve
        </Button>
      </>
    )
  }, [onHide, onReserveSeat, selected])

  return (
    <div className={clsx('seat-map-selection-input flex-grow-1', className)}>
      <SeatMapEditorInput
        className='flex-grow-1 py-4'
        selected={selected}
        onSelectedChange={onSelectedChange && handleSelectSeates}
        disabled={disabled}
        occupied={occupied}
        shared={shared}
        disableRowClick
        {...props}
      />
      <div className='d-flex justify-content-end'>{actionButtons}</div>
    </div>
  )
}
