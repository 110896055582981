import {useMemo} from 'react'
import {Route, RouteProps, Redirect} from 'react-router'
import {FeatureCode} from '../../utils/RBAC/Feature'
import {useAuthRole} from '../hooks/useAuthRole'

export interface AuthGuardRouteProps extends RouteProps {
  featureCode?: FeatureCode
  redirectUrl?: string
  adminOnly?: boolean
  readAndWrite?: boolean
}

export const AuthGuardRoute = ({
  featureCode,
  redirectUrl = '/',
  adminOnly,
  readAndWrite,
  ...routeProps
}: AuthGuardRouteProps) => {
  const authRole = useAuthRole()

  const canAccess = useMemo(() => {
    if (adminOnly) {
      return authRole?.isAdmin()
    } else if (!adminOnly && featureCode) {
      if (readAndWrite) {
        return authRole?.canManageFeature(featureCode)
      }

      return authRole?.canReadFeature(featureCode)
    } else if (!adminOnly && !featureCode) {
      return true
    }
    return null
  }, [adminOnly, authRole, featureCode, readAndWrite])

  if (canAccess) {
    return <Route {...routeProps} />
  } else if (canAccess === false) {
    return <Redirect to={redirectUrl} />
  }
  return null
}
