import {MetronicIconPath} from '../app/components/inputs/MetronicIcon'
import {ApplicationCode} from '../app/utils/RBAC/Application'
import {FeatureCode} from '../app/utils/RBAC/Feature'

export interface NavigationSection {
  title?: string
  menus: NavigationSectionMenuItem[]
}

export type NavigationSectionMenuItemStatus = 'danger' | 'warning' | 'success'

export type MenuDirectionType = 'right' | 'bottom' | 'left'
export interface NavigationSectionMenuItem {
  title: string
  intlId?: string
  to?: string
  icon?: string | MetronicIconPath
  subMenus?: NavigationSectionMenuItem[]
  status?: NavigationSectionMenuItemStatus
  direction?: MenuDirectionType | null
  group?: string
}

export interface AuthNavigationSectionMenuItem extends NavigationSectionMenuItem {
  applicationCode?: ApplicationCode
  featureCode?: FeatureCode
  isAdminOnly?: boolean
  direction?: MenuDirectionType | null
  subMenus?: AuthNavigationSectionMenuItem[]
}

export const SYS_MENUS: AuthNavigationSectionMenuItem = {
  title: 'System',
  isAdminOnly: true,
  icon: '/media/apps/EMS.svg',
  subMenus: [
    {
      to: '/system/user',
      title: 'Users',
    },
    {
      to: '/system/log',
      title: 'Logs',
    },
    {
      to: '/system/config',
      title: 'Configurations',
      status: 'warning',
    },
    {
      to: '/system/custom-field',
      title: 'Custom Fields',
    },
    {
      to: '/system/status',
      title: 'Status',
      status: 'success',
    },
    {
      group: 'roles',
      to: '/system/role',
      title: 'Access Roles',
    },
  ],
}

export const EVA_MENUS: AuthNavigationSectionMenuItem = {
  title: 'Event Virtual Assistant',
  applicationCode: 'EVA',
  icon: '/media/apps/EVA.svg',
  subMenus: [
    {
      featureCode: 'EVAAGENDA',
      to: '/eva/agenda',
      title: 'Agendas',
    },
    {
      featureCode: 'EVATOPICS',
      to: '/eva/topic',
      title: 'Topics',
    },
    {
      featureCode: 'EVAPOLLS',
      to: '/eva/poll',
      title: 'Polls',
    },
  ],
}

export const FNB_MENUS: AuthNavigationSectionMenuItem = {
  title: 'Food & Beverage',
  applicationCode: 'FNB',
  icon: '/media/apps/FANDB.svg',
  subMenus: [
    {
      featureCode: 'FNBOUTLETS',
      to: '/fnb/outlet',
      title: 'Outlets',
    },
    {
      featureCode: 'FNBPRODCATS',
      to: '/fnb/p-category',
      title: 'Product Categories',
    },
    {
      featureCode: 'FNBPRODUCTS',
      to: '/fnb/product',
      title: 'Products',
    },
    {
      featureCode: 'FNBUSERS',
      to: '/fnb/user',
      title: 'Users',
    },
    {
      featureCode: 'FNBORDERS',
      to: '/fnb/order',
      title: 'Orders',
    },
    {
      featureCode: 'FNBLOGS',
      to: '/fnb/outlet-logs',
      title: 'Outlet Logs',
    },
    {
      featureCode: 'FNBTABLERES',
      to: '/fnb/table-reservation',
      title: 'Table Reservations',
    },
    {
      featureCode: 'FNBQRGEN',
      to: '/fnb/qr-generator',
      title: 'QR Generator',
    },
    {
      featureCode: 'FNBIMPORT',
      to: '/fnb/import',
      title: 'Import',
    },
  ],
}

export const EMS_MENUS: AuthNavigationSectionMenuItem = {
  title: 'Event Management System',
  applicationCode: 'EMS',
  icon: '/media/apps/EMS.svg',
  subMenus: [
    {
      featureCode: 'EMSEVENTS',
      to: '/ems/event',
      title: 'Events',
    },
    {
      featureCode: 'EMSACTIVITY',
      to: '/ems/activity',
      title: 'Activities',
    },
    {
      featureCode: 'EMSPRODCAT',
      to: '/ems/p-category',
      title: 'Product Categories',
    },
    {
      featureCode: 'EMSPRODUCT',
      to: '/ems/product',
      title: 'Products',
    },
    {
      featureCode: 'EMSBUNDLE',
      to: '/ems/bundle',
      title: 'Bundles',
    },
    {
      featureCode: 'EMSBOOKINGS',
      to: '/ems/booking',
      title: 'Bookings',
    },
    {
      featureCode: 'EMSTICKETS',
      to: '/ems/ticket',
      title: 'Tickets',
    },
    EVA_MENUS,
    FNB_MENUS,
  ],
}

export const ACS_MENUS: AuthNavigationSectionMenuItem = {
  title: 'Access Control',
  applicationCode: 'ACS',
  icon: '/media/apps/ACS.svg',
  subMenus: [
    {
      featureCode: 'ACSVENUES',
      to: '/acs/venue',
      title: 'Venues',
    },
    {
      featureCode: 'ACSLOCATION',
      to: '/acs/location',
      title: 'Locations',
    },
    {
      featureCode: 'ACSGATES',
      to: '/acs/gate',
      title: 'Scanners',
    },
  ],
}

export const CMS_MENUS: AuthNavigationSectionMenuItem = {
  title: 'Content Management',
  applicationCode: 'CMS',
  icon: '/media/apps/CMS.svg',
  subMenus: [
    {
      featureCode: 'CMSPOSTS',
      to: '/cms/post',
      title: 'Posts',
    },
    {
      featureCode: 'CMSPAGES',
      to: '/cms/page',
      title: 'Pages',
    },
    {
      featureCode: 'CMSFAQS',
      to: '/cms/faq',
      title: 'FAQs',
    },
  ],
}

export const CUSTOMER_MENU: AuthNavigationSectionMenuItem = {
  title: 'Master Data',
  applicationCode: 'SVC',
  icon: {
    iconType: 'General',
    iconName: 'User',
  },
  subMenus: [
    {featureCode: 'SVCCUST', title: 'Guests', to: '/svc/customer'},
    {featureCode: 'SVCCUSTCATS', title: 'Groups', to: '/svc/customer-category'},
    {featureCode: 'SVCTEMPLATE', title: 'Templates', to: '/svc/template'},
    {featureCode: 'SVCIMPORT', title: 'Imports', to: '/svc/import', group: 'imports'},
    {
      title: 'Merchandise',
      applicationCode: 'MERCHANDISE',
      direction: 'right',
      subMenus: [
        {
          featureCode: 'MERCHANDISEPRODUCTS',
          title: 'Product Category',
          to: '/merchandise/product-category',
          group: 'Merchandise',
        },
        {
          featureCode: 'MERCHANDISEPRODCATS',
          title: 'Product',
          to: '/merchandise/product',
          group: 'Merchandise',
        },
      ],
    },
  ],
}

export const VOUCHER_MENU: AuthNavigationSectionMenuItem = {
  title: 'Vouchers',
  to: '/svc/voucher',
  featureCode: 'SVCVOUCHER',
  applicationCode: 'SVC',
  icon: {
    iconType: 'Shopping',
    iconName: 'Ticket',
  },
}

export const IMPORT_MENU: AuthNavigationSectionMenuItem = {
  title: 'Imports',
  to: '/svc/import',
  featureCode: 'SVCIMPORT',
  applicationCode: 'SVC',
  icon: {
    iconType: 'Files',
    iconName: 'Import',
  },
}

export const DASBOARD_MENU: AuthNavigationSectionMenuItem = {
  title: 'Dashboard',
  intlId: 'MENU.DASHBOARD',
  applicationCode: 'SVC',
  icon: '/media/icons/duotone/Layout/Layout-4-blocks.svg',
  to: '/dashboard',
  featureCode: 'SVCDASHBOARD',
}

export const EVENTS_MENU: AuthNavigationSectionMenuItem = {
  title: 'Events',
  applicationCode: 'EMS',
  to: '/ems/event',
  featureCode: 'EMSEVENTS',
}

export const DEFAULT_LAYOUT_MENUS = [
  DASBOARD_MENU,
  EVENTS_MENU,
  ACS_MENUS,
  CMS_MENUS,
  CUSTOMER_MENU,
  SYS_MENUS,
]
