import {useState, useCallback} from 'react'

import {PostReservation} from '../../../redux/CustomerPortalCRUD'
import {useFormik} from 'formik'
import {ReservationForm, roleFormValidationSchema} from './ReservationForm'
import {Drawer} from '../../../../../components/Drawer/Drawer'
import {useDrawerRef} from '../../../../../components/Drawer/useDrawerRef'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {initialValues} from '../../../hooks/useReservationData'
import {ReservationFormValues} from './ReservationForm'
import {getReservationPayload} from '../../../hooks/useReservationData'
import {EventModel} from '../../../../../models/ems/EventModel'
export interface ReservationFormCreateProps {
  drawerRef: (drawer: Drawer | null) => void
  onChange: () => void
  event?: EventModel
}

const ReservationFormCreate = ({drawerRef, onChange, event}: ReservationFormCreateProps) => {
  const [loading, setLoading] = useState(false)
  const [drawer, setDrawer] = useDrawerRef()
  const [isOpen, setIsOpen] = useState(false)
  const {pushError} = useAlerts()

  const createNewReservation = useCallback(
    async (values: ReservationFormValues) => {
      const payload = getReservationPayload(values)
      try {
        await PostReservation(payload)
        onChange()
      } catch (e) {
        throw e
      }
    },
    [onChange]
  )

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: roleFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await createNewReservation(values)
        handleCloseDrawer()
        drawer?.hide()
      } catch (e) {
        pushError(e)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleCloseDrawer = useCallback(() => {
    formik.resetForm()
    formik.setFieldValue('eventCode', event?.code)
    setIsOpen(false)
  }, [event?.code, formik])

  const handleDrawerRef = useCallback(
    (drawer: Drawer) => {
      drawerRef(drawer)
      setDrawer(drawer)
    },
    [drawerRef, setDrawer]
  )

  return (
    <Drawer ref={handleDrawerRef} onOpen={() => setIsOpen(true)} onClose={handleCloseDrawer}>
      <ReservationForm
        title='Create Reservation'
        onCloseButtonClick={drawer?.hide}
        isLoading={loading}
        formik={formik}
        isOpen={isOpen}
        event={event}
      />
    </Drawer>
  )
}

export default ReservationFormCreate
