import {ReactNode} from 'react'
import {HasCode} from '../../utils/idExtractor'
import {Link} from './Link'

export interface PortalEventLinkProps {
  className?: string
  event?: {
    code: string
    parent?: HasCode
  }
  children: ReactNode
}

export const PortalEventLink = ({className, event, children}: PortalEventLinkProps) => {
  if (event) {
    return (
      <Link className={className} to={`/events/${event.code}`}>
        {children}
      </Link>
    )
  }
  return null
}
