import {useMemo} from 'react'
import {EventModel} from '../../../../models/ems/EventModel'

export interface UseRsvpEventHelperParams {
  event?: EventModel | null
}
export const useRsvpEventHelper = ({event}: UseRsvpEventHelperParams) => {
  const isBasic = useMemo(() => {
    if (event) {
      return event.type?.code === 'BASIC'
    }
    return false
  }, [event])

  const isRsvp = useMemo(() => {
    if (event?.type) {
      return event.type?.code === 'RSVP'
    }
    return false
  }, [event])

  const isRegistrationRsvp = useMemo(() => {
    if (event) {
      return event.type?.code === 'RSVPREGISTRATION'
    }
    return false
  }, [event])

  const isGuestEvent = useMemo(() => {
    return isRsvp || isRegistrationRsvp || isBasic
  }, [isBasic, isRegistrationRsvp, isRsvp])

  return {isRsvp, isRegistrationRsvp, isGuestEvent}
}
