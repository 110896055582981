import {FC, useCallback, useState} from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {AsyncPageTitle} from '../../../components/layouts/AsyncPageTitle'
import {CustomerModel} from '../../../models/CustomerModel'
import CustomerDetailHeader from '../components/CustomerDetailHeader'
import {GetCustomerByCode} from '../redux/CustomerPortalCRUD'
import {BookingsPortalPage} from './BookingsPortalPage'
import {LinkBreadcrumbsToolbar} from '../../../components/layouts/DefaultLayout/LinkBreadcrumbsToolbar'
import CustomerPortalDetailBooking from './sub-pages/CustomerPortalDetailBooking'

interface RouteMatch {
  customerCode: string
  path: string
}

const CustomerDetails: FC = () => {
  const match = useRouteMatch<RouteMatch>()
  const {customerCode} = match.params
  const [customer, setCustomer] = useState<CustomerModel>()
  const {pushError} = useAlerts()

  const onRefresh = useCallback(async () => {
    try {
      const {data} = await GetCustomerByCode(customerCode)
      setCustomer(data)
    } catch (e) {
      pushError(e)
    }
  }, [customerCode, pushError])

  useOnChange(customerCode, () => {
    onRefresh()
  })

  return (
    <>
      <Switch>
        <Route path='/customer/:customerCode/bookings'>
          {customer && (
            <>
              <LinkBreadcrumbsToolbar
                title={`${customer.name} : Bookings`}
              ></LinkBreadcrumbsToolbar>
              <CustomerDetailHeader customer={customer} onRefresh={onRefresh} />
              <BookingsPortalPage customer={customer} />
            </>
          )}
        </Route>

        <Route path='/customer/:customerCode/booking/:bookingCode/booking-product/:bookingProductCode/ticket/:ticketCode/seat'>
          {customer && (
            <>
              <LinkBreadcrumbsToolbar
                title={`${customer.name} : Ticket Seat`}
              ></LinkBreadcrumbsToolbar>
              <CustomerDetailHeader customer={customer} onRefresh={onRefresh} />
              <AsyncPageTitle
                loading={!customer}
              >{`${customer?.name} : Ticket Seat`}</AsyncPageTitle>
              <CustomerPortalDetailBooking customer={customer} />
            </>
          )}
        </Route>
        <Route path='/customer/:customerCode/booking/:bookingCode/booking-product/:bookingProductCode/ticket'>
          {customer && (
            <>
              <LinkBreadcrumbsToolbar title={`${customer.name} : Tickets`}></LinkBreadcrumbsToolbar>
              <CustomerDetailHeader customer={customer} onRefresh={onRefresh} />
              <AsyncPageTitle loading={!customer}>{`${customer?.name} : Tickets`}</AsyncPageTitle>
              <CustomerPortalDetailBooking customer={customer} />
            </>
          )}
        </Route>

        <Route path='/customer/:customerCode/booking/:bookingCode/booking-product/:bookingProductCode/voucher'>
          {customer && (
            <>
              <LinkBreadcrumbsToolbar
                title={`${customer.name} : Vouchers`}
              ></LinkBreadcrumbsToolbar>
              <CustomerDetailHeader customer={customer} onRefresh={onRefresh} />
              <AsyncPageTitle loading={!customer}>{`${customer?.name} : Vouchers`}</AsyncPageTitle>
              <CustomerPortalDetailBooking customer={customer} />
            </>
          )}
        </Route>

        <Route path='/customer/:customerCode/booking/:bookingCode/booking-detail'>
          {customer && (
            <>
              <LinkBreadcrumbsToolbar
                title={`${customer.name} : Booking details`}
              ></LinkBreadcrumbsToolbar>
              <CustomerDetailHeader customer={customer} onRefresh={onRefresh} />
              <AsyncPageTitle
                loading={!customer}
              >{`${customer?.name} : Booking Detail`}</AsyncPageTitle>
              <CustomerPortalDetailBooking customer={customer} />
            </>
          )}
        </Route>

        <Redirect
          from='/customer/:customerCode'
          exact={true}
          to='/customer/:customerCode/bookings'
        />
        <Redirect to='/customer/:customerCode/bookings' />
      </Switch>
    </>
  )
}

export default CustomerDetails
