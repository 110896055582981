import {useMemo} from 'react'
import {Button} from '../../../../../components/inputs/Button'
import {MetronicIcon} from '../../../../../components/inputs/MetronicIcon'

export interface BookingTableActionsProps {
  onNewBookingClick?: () => void
}
export const BookingTableRightActions = ({onNewBookingClick}: BookingTableActionsProps) => {
  const addButton = useMemo(() => {
    if (onNewBookingClick) {
      return (
        <Button variant='primary' onClick={onNewBookingClick}>
          <MetronicIcon iconType='Navigation' iconName='Plus' /> New Booking
        </Button>
      )
    }
    return null
  }, [onNewBookingClick])
  return <>{addButton}</>
}
