/* eslint-disable react-hooks/exhaustive-deps */
import {useFormik} from 'formik'
import {v4 as uuidv4} from 'uuid'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import * as yup from 'yup'
import {useCallback, useMemo, useState} from 'react'
import {UpdateRegistration} from '../../member-registration/components/redux/CustomerRegistrationCRUD'
import {Button, ButtonVariant} from '../../../../components/inputs/Button'
import {ButtonCrumbAttributes} from '../../../../components/ButtonCrumbs/ButtonCrumb'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {pick} from 'lodash'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {
  NewRegistrationProductStep,
  NewRegistrationProductStepValues,
  STEP_PRODUCT_KEYS,
} from './steps/NewRegistrationProductStep'
import {
  NewRegistrationPaymentStep,
  NewRegistrationPaymentStepValues,
  STEP_PAYMENT_KEYS,
} from './steps/NewRegistrationPaymentStep'
import {ProductModel} from '../../../../models/ems/ProductModel'
import {ButtonCrumbs} from '../../../../components/ButtonCrumbs/ButtonCrumbs'

enum FormStep {
  PRODUCT = 'PRODUCT',
  PAYMENT = 'PAYMENT',
}

const StepOrder = [FormStep.PRODUCT, FormStep.PAYMENT]

export interface NewRegistrationWizardFormValues
  extends NewRegistrationProductStepValues,
    NewRegistrationPaymentStepValues {}

export const NewRegistrationWizard = () => {
  const {pushError} = useAlerts()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [currentStep, setCurrentStep] = useState(FormStep.PRODUCT)

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: async (values) => {
      try {
        const payload = getPayload(values)
        await UpdateRegistration(payload) // update to send paymend gateway api
        setHasSubmitted(true)
      } catch (e) {
        pushError(e)
      }
    },
    validationSchema,
    validateOnMount: true,
  })

  const getStepVariant = useCallback(
    (step: FormStep): ButtonVariant => {
      const isPrevious = StepOrder.indexOf(step) < StepOrder.indexOf(currentStep)
      if (isPrevious) {
        return 'success'
      }
      if (currentStep === step) {
        return 'primary'
      }
      return 'default'
    },
    [currentStep]
  )

  const crumbs = useMemo((): ButtonCrumbAttributes[] => {
    return [
      {
        value: FormStep.PRODUCT,
        label: <MetronicIcon iconType='Communication' iconName='Add-user' />,
        className: 'btn-icon',
        variant: getStepVariant(FormStep.PRODUCT),
      },
      {
        value: FormStep.PAYMENT,
        label: <MetronicIcon iconType='Shopping' iconName='Credit-card' />,
        className: 'btn-icon',
        variant: getStepVariant(FormStep.PAYMENT),
      },
    ]
  }, [getStepVariant])

  //get data from api
  const getProducts: ProductModel[] = [
    {
      code: 'PRD01',
      name: 'PRD-05 [Silver]',
      qty: 1,
      startedAt: '2022-07-06T20:00:00.000Z',
      endedAt: '2022-07-09T19:59:59.000Z',
      isSeated: false,
      isConsumable: false,
      isTimeslot: false,
      type: 'e-ticket',
      updatedAt: '2022-07-06T12:07:23.000Z',
      price: 550,
    },
    {
      code: 'PRD02',
      name: 'PRD-06 [Gold]',
      qty: 1,
      price: 100,
      startedAt: '2022-07-06T20:00:00.000Z',
      endedAt: '2022-07-09T19:59:59.000Z',
      isSeated: false,
      isConsumable: false,
      isTimeslot: false,
      type: 'card',
      updatedAt: '2022-07-06T12:09:10.000Z',
    },
    {
      code: 'PRD03',
      name: 'PRD-07 [Premium]',
      qty: 1,
      price: 100,
      startedAt: '2022-07-06T20:00:00.000Z',
      endedAt: '2022-07-09T19:59:59.000Z',
      isSeated: false,
      isConsumable: false,
      isTimeslot: false,
      type: 'card',
      updatedAt: '2022-07-06T12:09:10.000Z',
    },
    {
      code: 'PRD04',
      name: 'PRD-09 [Basic]',
      qty: 1,
      price: 100,
      startedAt: '2022-07-06T20:00:00.000Z',
      endedAt: '2022-07-09T19:59:59.000Z',
      isSeated: false,
      isConsumable: false,
      isTimeslot: false,
      type: 'card',
      updatedAt: '2022-07-06T12:09:10.000Z',
    },
    {
      code: 'PRD05',
      name: 'PRD-11 [Super Basic]',
      qty: 1,
      price: 100,
      startedAt: '2022-07-06T20:00:00.000Z',
      endedAt: '2022-07-09T19:59:59.000Z',
      isSeated: false,
      isConsumable: false,
      isTimeslot: false,
      type: 'card',
      updatedAt: '2022-07-06T12:09:10.000Z',
    },
    {
      code: 'PRD06',
      name: 'PRODUCT LOCATION WITH A VERY LONG PRODUCT!',
      qty: 1,
      price: 200,
      startedAt: '2022-07-05T20:00:00.000Z',
      endedAt: '2022-07-31T19:59:59.000Z',
      isSeated: false,
      isConsumable: true,
      isTimeslot: false,
      type: 'e-ticket',
      updatedAt: '2022-10-02T14:59:52.000Z',
    },
  ]

  const stepForm = useMemo(() => {
    switch (currentStep) {
      case FormStep.PRODUCT: {
        return <NewRegistrationProductStep formik={formik} products={getProducts} />
      }
      case FormStep.PAYMENT: {
        return <NewRegistrationPaymentStep formik={formik} />
      }
    }
  }, [currentStep, formik, getProducts])

  const handleNextClick = useCallback(() => {
    setCurrentStep((currentStep) => {
      return StepOrder[StepOrder.indexOf(currentStep) + 1]
    })
  }, [])

  const handlePreviousClick = useCallback(() => {
    setCurrentStep((currentStep) => {
      return StepOrder[StepOrder.indexOf(currentStep) - 1]
    })
  }, [])

  const stepHasErrors = useCallback(
    (step: FormStep): boolean => {
      let errors = formik.errors
      switch (step) {
        case FormStep.PRODUCT: {
          errors = pick(formik.errors, STEP_PRODUCT_KEYS)
          break
        }
        case FormStep.PAYMENT: {
          errors = pick(formik.errors, STEP_PAYMENT_KEYS)
          break
        }
      }
      return Object.values(errors).some((value) => Boolean(value))
    },
    [formik.errors]
  )

  const productFormHasValues = useMemo(() => {
    const values = pick(formik.values, STEP_PRODUCT_KEYS)
    return Object.values(values).some((value) => Boolean(value))
  }, [formik.values])

  const actions = useMemo(() => {
    const currentStepIndex = StepOrder.indexOf(currentStep)
    const hasPrevious = currentStepIndex > 0
    const hasNext = currentStepIndex < StepOrder.length - 1
    return (
      <>
        <div className='row mb-3'>
          <div className='col col-xs-12 col-md'></div>
          {hasNext && (
            <div className='col col-xs-12'>
              <Button
                className='w-100'
                variant='primary'
                type='button'
                disabled={stepHasErrors(currentStep)}
                onClick={handleNextClick}
              >
                Next
              </Button>
            </div>
          )}
        </div>
        <div className='row g-3 d-flex justify-content-end'>
          {hasPrevious && (
            <div className='col-xs-12 col-md-6'>
              <Button className='w-100' variant='info' type='button' onClick={handlePreviousClick}>
                Previous
              </Button>
            </div>
          )}
          {!hasNext && (
            <div className='col-xs-12 col-md-6 '>
              <Button
                className='w-100'
                variant='primary'
                type='submit'
                disabled={!productFormHasValues || !formik.isValid || formik.isSubmitting}
              >
                Pay
              </Button>
            </div>
          )}
        </div>
      </>
    )
  }, [
    productFormHasValues,
    currentStep,
    formik.isSubmitting,
    formik.isValid,
    handleNextClick,
    handlePreviousClick,
    stepHasErrors,
  ])

  const formTitle = useMemo(() => {
    if (!hasSubmitted) {
      let message: string = ''
      switch (currentStep) {
        case FormStep.PRODUCT: {
          message = 'Product'
          break
        }
        case FormStep.PAYMENT: {
          message = 'Package Summary'
          break
        }
      }
      return <h1 className='text-dark mb-3'>{message}</h1>
    }
    return (
      <>
        <p className='text-gray-400 fw-bold fs-4'>Redirect this to invoice page</p>
      </>
    )
  }, [currentStep, hasSubmitted])

  const debouncedValidate = useDebounce(500)

  useOnChange(formik.values, () => {
    debouncedValidate(() => {
      formik.validateForm()
    })
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='text-center mb-10'>{formTitle}</div>
      {!hasSubmitted && (
        <>
          <div className='d-flex justify-content-center mb-5'>
            <ButtonCrumbs crumbs={crumbs} />
          </div>
          {stepForm}
          {actions}
        </>
      )}
    </form>
  )
}

const getPayload = (booking: NewRegistrationWizardFormValues) => {
  const formData = new FormData()
  // formData.append('name', booking.name)
  formData.append('email', booking.email)

  return formData
}

const INITIAL_VALUES: NewRegistrationWizardFormValues = {
  name: '',
  email: '',
  products: [{count: 0, value: '', id: uuidv4()}],
  paymentType: '',
  creditCardNumber: '',
  cvv: '',
  cardExpiryMonth: '',
  cardExpiryYear: '',
  promoCode: '',
}
const validationSchema = yup.object().shape({
  name: yup.string(),
  products: yup.array().test('has-atleast-one', 'Please add atlease one product', (value) => {
    if (value && value.length > 0) {
      for (let item of value) {
        if (item.count === 0) return false
        else {
          return true
        }
      }
      return false
    } else return false
  }),
})
