import {Redirect, Route, Switch} from 'react-router-dom'
import {RegistrationPage} from '../../modules/customer-registration/pages/RegistrationPage'
import {RSVPPage} from '../../modules/customer-registration/pages/RSVPPage'
import {ThankyouPage} from '../../modules/customer-registration/pages/ThankYouPage'
import {FullPage403} from '../common/FullPage403'
import {FullPage404} from '../common/FullPage404'

export const Routes = () => {
  return (
    <Switch>
      <Route path='/auth/register' component={RegistrationPage} />
      <Route path='/auth/rsvp' component={RSVPPage} />
      <Route path='/rsvp/thankyou' component={ThankyouPage} />
      <Route path='/error/404'>
        <FullPage404 link='/' />
      </Route>
      <Route path='/error/403'>
        <FullPage403 link='https://webntech.ae' />
      </Route>
      <Redirect to='/error/404' />
    </Switch>
  )
}
