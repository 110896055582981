import moment from 'moment'
import {useMemo} from 'react'

export interface DateTimeFormatterProps {
  className?: string
  children: Date | string
  ampm?: boolean
}

export const DateTimeFormatter = ({children, className, ampm}: DateTimeFormatterProps) => {
  const dateString = useMemo(() => {
    const momentDate = moment(children)
    if (ampm) {
      return momentDate.format('MMM DD, YYYY h:mm A')
    }
    return momentDate.format('MMM DD, YYYY HH:mm')
  }, [ampm, children])

  return <time className={className}>{dateString}</time>
}
