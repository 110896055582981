import {useCallback, useEffect, useMemo} from 'react'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useSeatMapPortalReservationState} from '../../hooks/useSeatMapPortalReservationState'
import {SeatMapSelectionPortalInput} from './SeatMapSelectionPortalInput'
import {SeatMapValue} from '../../../../components/inputs/SeatMapInput/SeatMapValue'

import {useCustomerAuth} from '../../hooks/useCustomerAuth'
import {GetvailableSeatMaps} from '../../redux/CustomerPortalCRUD'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {useSafeStateUpdate} from '../../../../components/hooks/useSafeStateUpdate'
import {GetLocationByCode} from '../../../default/acs/redux/AcsCRUD'

interface TicketDetailSeatMapsProps {
  ticket?: TicketPortalModel | null
  count: number
  setSelected: (seats: SeatMapValue) => void
  selected?: SeatMapValue
}
export const TicketDetailSeatMaps = ({
  ticket,
  count,
  selected,
  setSelected,
}: TicketDetailSeatMapsProps) => {
  const auth = useCustomerAuth()
  const {
    resetState: resetSeatMapState,
    columns,
    rows,
    extra,
    disabled,
    shared,
    active,
    hidden,
    isRightToLeft,
    isBottomToTop,
    setAvailableSeates,
    setIsLoading,
  } = useSeatMapPortalReservationState()
  const {push, pushError} = useAlerts()
  const safeUpdate = useSafeStateUpdate()
  const customerCode = useMemo(() => {
    return auth?.getUser().code
  }, [auth])

  const resetLocationByCode = useCallback(
    async (locationCode: string, eventCode: string, productCode: string) => {
      const doneLoading = setIsLoading(locationCode)
      try {
        const {data} = await GetLocationByCode(locationCode)
        if (!data.seatMap) {
          push({
            message: `No seat maps available for ${data.name}`,
            variant: 'danger',
            timeout: 5000,
          })
        } else {
          safeUpdate(() => resetSeatMapState(data.seatMap))
        }
      } catch (e) {
        pushError(e)
      } finally {
        if (customerCode) {
          const {
            data: {availableSeats},
          } = await GetvailableSeatMaps(locationCode, productCode, eventCode, customerCode)
          if (customerCode) setAvailableSeates(new SeatMapValue(availableSeats))
        }
        doneLoading()
      }
    },
    [setIsLoading, push, safeUpdate, resetSeatMapState, pushError, setAvailableSeates, customerCode]
  )

  useEffect(() => {
    if (ticket && ticket.productCode) {
      resetLocationByCode(ticket.locationCode, ticket.eventCode, ticket.productCode)
    }
  }, [resetLocationByCode, ticket])

  const occupied = useMemo(() => {
    let activeSeats = new SeatMapValue()
    if (extra) {
      activeSeats = activeSeats.union(extra)
    }
    if (active) {
      activeSeats = activeSeats.union(active)
    }
    if (shared) {
      activeSeats = activeSeats.union(shared)
    }
    return new SeatMapValue(rows, columns).difference(activeSeats)
  }, [active, columns, extra, rows, shared])

  const handleOnChange = useCallback(
    (values: SeatMapValue) => {
      const remaining = count - values.getValueCount()
      if (remaining >= 0) {
        setSelected(values)
      }
    },
    [count, setSelected]
  )

  return (
    <SeatMapSelectionPortalInput
      onSelectedChange={handleOnChange}
      selected={selected}
      columns={columns}
      rows={rows}
      occupied={occupied}
      shared={shared}
      extra={extra}
      disabled={disabled}
      hidden={hidden}
      isRightToLeft={isRightToLeft}
      isBottomToTop={isBottomToTop}
    />
  )
}
