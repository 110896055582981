import {FormikContextType} from 'formik'
import {useCallback, useMemo, useState} from 'react'
import {Badge} from '../../../../components/badge/Badge'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {Button} from '../../../../components/inputs/Button'
import {
  TreeNodeItem,
  TreeSelectLastLevel,
} from '../../../../components/inputs/TreeSelectLastLevel/TreeSelectLastLevel'
import {useModalState} from '../../../../components/modals/useModalState'
import {EventModel} from '../../../../models/ems/EventModel'
import {ApiTree} from '../../../../utils/Tree/ApiTree'
import {ReservationFormValues} from '../DrawerForm/ReservationFormDrawer/ReservationForm'
import {ReservationSeatMap} from '../ReservationSeatMap/ReservationSeatMap'

interface LocationInputProps {
  formik: FormikContextType<ReservationFormValues>
  locationvalue: string[]
  locationItems: TreeNodeItem[]
  handleLocationChange: (values: string[]) => void
  disabled: boolean
  event?: EventModel
}

export const LocationInput = ({
  locationvalue,
  locationItems,
  handleLocationChange,
  formik,
  event,
  disabled,
}: LocationInputProps) => {
  const {
    isOpen: isSelectionModalOpen,
    hide: hideSelectionModal,
    open: openSelectionModal,
  } = useModalState()

  const [show, setShow] = useState<boolean>(false)

  useOnChange(formik.values.eventCode, () => {
    if (!formik.values.eventCode) {
      setShow(false)
    }
  })

  const handleOnAdd = useCallback(() => {
    setShow(true)
  }, [])

  const selectedLocationName = useMemo(() => {
    if (locationvalue && locationvalue.length > 0 && locationItems) {
      const tree = ApiTree.fromTreeSelectItems(locationItems)
      const found = tree.findByCode(locationvalue[0])
      if (found) {
        return found.name
      }
    }
    return 'Select a location'
  }, [locationItems, locationvalue])

  const seatsDetails = useMemo(() => {
    if (formik.values.locations) {
      return formik.values.locations.map((location) => {
        return Object.keys(location.details).map((key: string) => {
          return (
            <div className='d-flex w-100'>
              <div>
                <Badge className='me-3 mb-5' variant='success'>
                  {key}
                </Badge>
              </div>
              <div className='flex-grow-1'>
                {location.details[key].map((seat, index) => {
                  return (
                    <span>
                      {seat}
                      {location.details[key].length > 0 &&
                        index !== location.details[key].length - 1 &&
                        ' - '}
                    </span>
                  )
                })}
              </div>
            </div>
          )
        })
      })
    }
  }, [formik.values.locations])

  return (
    <>
      <Button
        type='button'
        size='sm'
        variant='primary'
        onClick={handleOnAdd}
        disabled={disabled}
        uppercase={false}
        className='my-5'
      >
        {disabled ? 'No locations available' : 'Add Location'}
      </Button>
      {show && (
        <>
          <TreeSelectLastLevel
            label={selectedLocationName}
            radioName='parentLocation'
            values={locationvalue}
            items={locationItems}
            onChange={handleLocationChange}
            isLastLevelSelect
          />

          {seatsDetails}

          {locationvalue && locationvalue.length > 0 && (
            <Button type='button' size='sm' variant='primary' onClick={openSelectionModal}>
              Reserve Seats
            </Button>
          )}
        </>
      )}

      <ReservationSeatMap
        event={event}
        locationItems={locationItems}
        locationvalue={locationvalue}
        onClose={hideSelectionModal}
        open={isSelectionModalOpen}
        formik={formik}
      />
    </>
  )
}
