import {useMemo} from 'react'
import {RowAction} from '../../../../../components/tables/actions/RowAction'
import {ReservationDetailsModel} from '../../../../../models/customer-portal/ReservationDetalsModel'

export interface CustomerBookingTableActionsProps {
  onAssignSeats?: (data: ReservationDetailsModel) => void
  data: ReservationDetailsModel
  loading?: boolean
}

export const ReservationDetailsTableActions = ({
  data,
  onAssignSeats,
  loading,
}: CustomerBookingTableActionsProps) => {
  const assignSeats = useMemo(() => {
    if (isViews(data)) {
      return (
        <RowAction
          onClick={onAssignSeats}
          data={data}
          loading={loading}
          iconType='Home'
          iconName='Armchair'
          tooltip='View seats'
          variant='info'
        />
      )
    }
  }, [data, loading, onAssignSeats])

  return <>{assignSeats}</>
}

const isViews = (data: ReservationDetailsModel) => {
  return (
    !['closed', 'released'].includes(data.reservationModuleStatus) &&
    data.reservationModuleType !== 'product'
  )
}
