import {useCallback, useMemo, useState} from 'react'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {VerifyRSVPHash} from '../components/redux/CustomerRegistrationCRUD'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useQueryParam} from '../../../components/hooks/useQueryParam'
import {useHistory} from 'react-router'
import {TextInput} from '../../../components/inputs'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {DelegateGuestRsvp} from '../../default/svc/redux/SvcCRUD'
import {Button} from 'react-bootstrap'

type Answer = 'yes' | 'no' | 'maybe'
interface VerifyRSVPHashResponse {
  error: string
  data: {
    answer: Answer
    productCode?: string
    eventCode?: string
    guestCode?: string
  }
}

interface answerParams {
  message: string
  answer: Answer
  productCode?: string
  eventCode?: string
  guestCode?: string
}

interface DelegateFormValues {
  name: string
  email: string
  customerOrganization?: string
}

const delegateSchema = Yup.object().shape({
  name: Yup.string().required('Email is required'),
  customerOrganization: Yup.string().optional(),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

const initialValues: DelegateFormValues = {
  name: '',
  email: '',
  customerOrganization: '',
}

export const RSVPPage = () => {
  const history = useHistory()
  const {value: hash} = useQueryParam('hash')
  const [hashData, setHashData] = useState<answerParams | null>()
  const [loading, setLoading] = useState<boolean>(false)

  const {pushError} = useAlerts()

  const verifyRSVPHash = useCallback(async () => {
    if (hash) {
      try {
        const {data}: {data: VerifyRSVPHashResponse} = await VerifyRSVPHash(hash)
        setHashData({
          message: data.error,
          answer: data.data.answer,
          productCode: data.data.productCode,
          guestCode: data.data.guestCode,
          eventCode: data.data.eventCode,
        })
      } catch (err) {
        pushError(err)
        history.replace('/error/403')
      } finally {
        setLoading(false)
      }
    }
  }, [hash, history, pushError])

  useOnChange(hash, () => {
    if (!hash) history.replace('/error/403')
    verifyRSVPHash()
  })

  const handleSubmit = useCallback(
    async (values: DelegateFormValues) => {
      try {
        if (hashData && hashData.guestCode && hashData.eventCode) {
          const {data} = await DelegateGuestRsvp({
            name: values.name,
            email: values.email,
            customerOrganization: values?.customerOrganization,
            guestCode: hashData.guestCode,
            eventCode: hashData.eventCode,
            productCode: hashData?.productCode,
          })

          if (data) {
            history.push('/rsvp/thankyou')
          }
        } else history.replace('/error/403')
      } catch (err) {
        pushError(err)
      }
    },
    [hashData, history, pushError]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: delegateSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await handleSubmit(values)
      } catch (err) {
        pushError(err)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const message = useMemo(() => {
    if (hashData) {
      if (hashData.answer === 'yes') {
        return (
          <div className='d-flex flex-column gap-4'>
            <h4>Thank you for accepting our invitation!</h4>
            <div>
              An official confirmation email with further details will be sent to your inbox soon.
              Once again, we appreciate your positive response and look forward to welcoming you.
            </div>
            <div>
              If you'd wish to extend an invitation to a colleague or a partner, kindly contact us
              at <a href='mailto:tedx@solaax.com'>tedx@solaax.com</a>
            </div>
          </div>
        )
      } else if (hashData.answer === 'no') {
        return (
          <div className='d-flex flex-column gap-4'>
            <h4>We're sorry to hear that you won't be able to join us at TEDx Eindhoven.</h4>
            <div>
              We appreciate you taking the time to respond. If your circumstances change or you
              would like to delegate this invitation to a colleague, please let us know at{' '}
              <a href='mailto:tedx@solaax.com'>tedx@solaax.com</a>
            </div>
            <div>We look forward to connecting with you in the future.</div>
          </div>
        )
      } else {
        return <h4>Thank you for your response!</h4>
      }
    }
    return null
  }, [hashData])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed'>
      {loading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          Please wait...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
      {!loading && (
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 min-h-100vh'>
          <a target='_blank' rel='noreferrer' href='https://solaax.com'>
            <img
              alt='tedx'
              src='/media/saas/tedx-solaax-1.png'
              className='img-fluid w-500px w-100 mb-8'
            />
          </a>
          {message && (
            <div
              className='w-sm-500px w-300px rounded shadow-sm p-10 p-lg-15 mx-auto text-center'
              style={{
                background: '#191a1d',
              }}
            >
              {message}
            </div>
          )}
          {hashData?.answer === 'maybe' && (
            <>
              <div className='w-sm-500px w-300px mt-5'>
                <div className='d-flex flex-column gap-4'>
                  <div>
                    We understand that you are unable to attend the TEDx Eindhoven event, but we
                    appreciate your interest in delegating your invitation.
                  </div>
                  <div>
                    In the box below, please provide us with the name and email address of the
                    person you'd like to extend your invitation to. We'll make sure to reach out to
                    them with all the necessary details.
                  </div>
                  <form className='form w-100' onSubmit={formik.handleSubmit}>
                    <TextInput
                      label='Name'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('name')}
                      errorMessage={formik.errors.name}
                      isTouched={formik.touched.name}
                    />

                    <TextInput
                      label='Email'
                      type='email'
                      autoComplete='off'
                      {...formik.getFieldProps('email')}
                      errorMessage={formik.errors.email}
                      isTouched={formik.touched.email}
                    />

                    <TextInput
                      label='Organization'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('customerOrganization')}
                      errorMessage={formik.errors.customerOrganization}
                      isTouched={formik.touched.customerOrganization}
                    />
                    <div className='d-flex flex-wrap justify-content-center'>
                      <Button
                        variant='light-primary'
                        className='w-100'
                        type='button'
                        disabled={formik.isSubmitting}
                        onClick={() => formik.handleSubmit()}
                      >
                        Send
                      </Button>
                    </div>
                  </form>

                  <div>
                    Once again, we thank you for your consideration and hope to have the opportunity
                    to connect with you in the future.
                  </div>
                  <div>
                    For any queries or questions, kindly contact us at{' '}
                    <a href='mailto:tedx@solaax.com'>tedx@solaax.com</a>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className='d-flex gap-7 mt-10'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.linkedin.com/company/tedxeindhoven'
            >
              <img
                alt='tedx linkedin'
                src='/media/svg/social-logos/tedx/linkedin.png'
                width='30px'
              />
            </a>

            <a target='_blank' rel='noreferrer' href='https://twitter.com/tedxeindhoven'>
              <img alt='tedx twitter' src='/media/svg/social-logos/tedx/twitter.png' width='30px' />
            </a>

            <a target='_blank' rel='noreferrer' href='https://www.instagram.com/tedxeindhoven'>
              <img
                alt='tedx instagram'
                src='/media/svg/social-logos/tedx/instagram.png'
                width='30px'
              />
            </a>
          </div>
          <a target='_blank' rel='noreferrer' href='https://solaax.com'>
            <img
              alt='powered by solaax'
              src='/media/saas/powered-by-solaax.png'
              className='h-50px mt-12'
            />
          </a>
        </div>
      )}
    </div>
  )
}
