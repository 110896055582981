import clsx from 'clsx'
import {ReactNode, useCallback, useMemo} from 'react'
import {MetronicIcon} from '../inputs/MetronicIcon'
import {DateFormatter} from '../utils/formatter/DateFormatter'
import {DateTimeFormatter} from '../utils/formatter/DateTimeFormatter'
import type {BaseTableColumnOptions} from './TableColumn'
import {TableColumnBadge, TableColumnBadgeVariant} from './TableColumnBadge'
import {useColumnStyle} from './useColumnStyle'

export type TableColumnType = 'date' | 'datetime' | 'number' | 'string' | 'boolean'

export interface TableColumnFieldOptions<T> extends BaseTableColumnOptions<T> {
  type?: TableColumnType
  dataExtract?: (data: T) => any
  variant?: TableColumnBadgeVariant
}

export interface TableColumnFieldProps<T> {
  data: T
  options: TableColumnFieldOptions<T>
}

export const TableColumnField = <T,>({data, options}: TableColumnFieldProps<T>) => {
  const {className, field, type = 'string', dataExtract, variant, cellStyle: style} = options

  const columnStyle = useColumnStyle(style)

  const stringColumn = useCallback((data: T) => {
    return String(data)
  }, [])

  const dateColumn = useCallback(
    (data: T) => {
      if (data instanceof Date || typeof data === 'string') {
        if (type === 'datetime') {
          return (
            <DateTimeFormatter className='text-uppercase text-nowrap'>{data}</DateTimeFormatter>
          )
        }
        return <DateFormatter className='text-uppercase text-nowrap'>{data}</DateFormatter>
      }
      return stringColumn(data)
    },
    [stringColumn, type]
  )

  const checkboxColumn = useCallback((data: T) => {
    let value = false
    if (typeof data === 'boolean') {
      value = data
    }
    if (value) {
      return (
        <MetronicIcon
          className='svg-icon-success svg-icon-1hx'
          iconType='Navigation'
          iconName='Check'
        />
      )
    }
    return null
  }, [])

  const getColumnNode = useCallback(
    (data: T) => {
      switch (type) {
        case 'date':
        case 'datetime':
          return dateColumn(data)
        case 'string':
          return stringColumn(data)
        case 'number':
          return stringColumn(data)
        case 'boolean':
          return checkboxColumn(data)
        default:
          return stringColumn(data)
      }
    },
    [dateColumn, stringColumn, checkboxColumn, type]
  )

  const applyVariant = useCallback(
    (node: ReactNode) => {
      if (variant) {
        return <TableColumnBadge variant={variant}>{node}</TableColumnBadge>
      }
      return node
    },
    [variant]
  )

  const children = useMemo(() => {
    if (!data) {
      return null
    }
    let displayedData: any = (data as Record<string, unknown>)[field]

    if (dataExtract) {
      displayedData = dataExtract(data)
    }
    if (displayedData === undefined || displayedData === null) {
      return null
    }
    let node = getColumnNode(displayedData)

    return applyVariant(node)
  }, [data, dataExtract, getColumnNode, applyVariant, field])

  return (
    <td
      className={clsx(
        className,
        options.cellStyle?.align && `text-${options.cellStyle.align}`,
        options.classes?.row
      )}
      style={columnStyle}
    >
      {children}
    </td>
  )
}
