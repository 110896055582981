import {useCallback, useMemo} from 'react'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useLoadingState} from '../../../components/hooks/useLoadingState'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {SwalUtils} from '../../../utils/SwalUtils'
import {DisableVoucher} from '../redux/CustomerPortalCRUD'

interface Props {
  onRefresh?: () => void
  onRefreshCallback?: () => void
}
export const useVoucherTableActions = ({onRefresh, onRefreshCallback}: Props) => {
  const {isKeyLoading, isLoading, setIsLoading} = useLoadingState()
  const {pushError, push} = useAlerts()

  const disable = useCallback(
    async (codes: string[]) => {
      const confirmation = await SwalUtils.confirm({
        titleText: 'Disable Voucher',
        text: 'Are you sure?',
        confirmButtonText: "Don't Disable!",
        icon: 'question',
        cancelButtonText: 'Disable!',
      })
      if (!confirmation.isConfirmed) {
        const doneLoading = setIsLoading(codes)
        try {
          await DisableVoucher(codes)
          push({
            message: `successfully disabled.`,
            timeout: 5000,
            variant: 'success',
          })
          onRefresh && onRefresh()
          onRefreshCallback && onRefreshCallback()
        } catch (e) {
          pushError(e)
        } finally {
          doneLoading()
        }
      }
    },
    [onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const handleDisableBulk = useCallback(
    (data: VoucherModel[]) => {
      disable(data.map((bookings) => bookings.code))
    },
    [disable]
  )

  const handleDisableSingle = useCallback(
    (data: VoucherModel) => {
      disable([data.code])
    },
    [disable]
  )

  return useMemo(
    () => ({
      isKeyLoading,
      isLoading,
      handleDisableBulk,
      handleDisableSingle,
    }),
    [isKeyLoading, isLoading, handleDisableBulk, handleDisableSingle]
  )
}

export const isVoucherShareable = (ticket: VoucherModel) => {
  return ticket.status === 'active' || ticket.status === 'sent'
}
