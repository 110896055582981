import {ReactNode, useMemo} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useCustomerAuth} from '../../modules/customer-portal/hooks/useCustomerAuth'
import {AuthPage} from '../../modules/customer-portal/pages/AuthPage'
import {FullPage403} from '../common/FullPage403'
import {FullPage404} from '../common/FullPage404'
import {Logout} from '../common/Logout'
import {PrivateRoutes} from './PrivateRoutes'
import {PORTA_DEFAULT_LAYOUT_MENUS} from '../../../config/portalDrawer'
import {Layout} from '../../components/layouts/DefaultLayout/Layout'
import {HeaderControls} from '../../components/layouts/DefaultLayout/Header/HeaderControls'
import {AuthPortalHeaderThumbnail} from '../../modules/customer-portal/components/AuthPortalHeaderThumbnail'

export const Routes = () => {
  const auth = useCustomerAuth()
  const authenticatedRoutes = useMemo(() => {
    if (!auth) {
      return <Redirect to='/auth/login' />
    } else if (auth.isFirstLogin()) {
      return <Redirect to='/auth/password-reset' />
    }
    return (
      <Layout menus={PORTA_DEFAULT_LAYOUT_MENUS}>
        <HeaderControls>
          <AuthPortalHeaderThumbnail />
        </HeaderControls>
        <PrivateRoutes />
      </Layout>
    )
  }, [auth])

  const unauthenticatedRoutes = useMemo(() => {
    let redirectFirstLogin: ReactNode
    if (auth && auth.isFirstLogin()) {
      redirectFirstLogin = <Redirect to='/auth/password-reset' />
    }

    if (!auth || auth?.isFirstLogin()) {
      return (
        <Route>
          {redirectFirstLogin}
          <AuthPage />
        </Route>
      )
    }
    return <Redirect from='/auth' to='/' />
  }, [auth])

  return (
    <Switch>
      <Route path='/logout' component={Logout} />
      <Route path='/auth/login/:hash' component={AuthPage} />
      <Route path='/auth/login' component={AuthPage} />
      <Route path='/error/404'>
        <FullPage404 link='/' />
      </Route>
      <Route path='/error/403'>
        <FullPage403 link='https://webntech.ae' />
      </Route>
      {unauthenticatedRoutes}
      {authenticatedRoutes}
    </Switch>
  )
}
