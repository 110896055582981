import {useCallback, useMemo} from 'react'
import {FilterTable} from '../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {useModalState} from '../../../../../components/modals/useModalState'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {FilterModel} from '../../../../../models/FilterModel'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {useLoadingState} from '../../../../../components/hooks/useLoadingState'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {QrCodeTableFilterAction} from '../../../../../components/tables/actions/QrCodeTableFilterAction'
import {ReservationDetailsTableActions} from './ReservationDetailsTableActions'
import {ReservationDetailsModel} from '../../../../../models/customer-portal/ReservationDetalsModel'
import {ReservationDetailsStatusColumn} from './ReservationDetailsStatusColumn'
import {useSeatMapPortalReservationState} from '../../../hooks/useSeatMapPortalReservationState'
import {
  SeatMapValue,
  SeatMapValueObject,
} from '../../../../../components/inputs/SeatMapInput/SeatMapValue'
import {GetLocationByCode} from '../../../../default/acs/redux/AcsCRUD'
import {useSafeStateUpdate} from '../../../../../components/hooks/useSafeStateUpdate'
import {SeatMapSelectionPortalInput} from '../../SeatTicketManifest/SeatMapSelectionInput'
import {ResponsiveModal} from '../../../../../components/modals/ResponsiveModal'
import {MetronicIconButton} from '../../../../../components/inputs/MetronicIconButton'
import {Link} from 'react-router-dom'

export interface ReservationDetailsTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<ReservationDetailsModel>
  reservationCode?: string
}

export const ReservationDetailsTable = ({data, onFilter}: ReservationDetailsTableProps) => {
  const {isKeyLoading} = useLoadingState()
  const {open: openModal, hide: hideModal, isOpen} = useModalState()
  const {
    setAvailableSeates,
    extra,
    disabled,
    columns,
    active,
    shared,
    rows,
    hidden,
    isRightToLeft,
    isBottomToTop,
    resetState: resetSeatMapState,
    setIsLoading,
  } = useSeatMapPortalReservationState()
  const {push, pushError} = useAlerts()
  const safeUpdate = useSafeStateUpdate()
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'customer-portal-reservation-details',
  })

  const getSeats = useCallback(
    async (locationCode: string, seats: SeatMapValueObject) => {
      const doneLoading = setIsLoading(locationCode)
      try {
        const {data} = await GetLocationByCode(locationCode)
        if (!data.seatMap) {
          push({
            message: `No seat maps available for ${data.name}`,
            variant: 'danger',
            timeout: 5000,
          })
        } else {
          safeUpdate(() => resetSeatMapState(data.seatMap))
        }
      } catch (e) {
        pushError(e)
      } finally {
        setAvailableSeates(new SeatMapValue(seats))
        openModal()
        doneLoading()
      }
    },
    [setIsLoading, push, safeUpdate, resetSeatMapState, pushError, setAvailableSeates, openModal]
  )

  const getSeatAssignmentHandler = useCallback(
    async (data: ReservationDetailsModel) => {
      getSeats(data.code, data.details)
    },
    [getSeats]
  )

  const idExtractor = useCallback((data: ReservationDetailsModel) => {
    return data.code
  }, [])

  const rowActions = useCallback(
    (data: ReservationDetailsModel) => (
      <ReservationDetailsTableActions
        data={data}
        onAssignSeats={getSeatAssignmentHandler}
        loading={isKeyLoading(data.code)}
      />
    ),
    [getSeatAssignmentHandler, isKeyLoading]
  )

  const tableItems = useMemo(() => {
    return data?.data || []
  }, [data?.data])

  const occupied = useMemo(() => {
    let activeSeats = new SeatMapValue()
    if (extra) {
      activeSeats = activeSeats.union(extra)
    }
    if (active) {
      activeSeats = activeSeats.union(active)
    }
    if (shared) {
      activeSeats = activeSeats.union(shared)
    }
    return new SeatMapValue(rows, columns).difference(activeSeats)
  }, [active, columns, extra, rows, shared])

  return (
    <>
      <FilterTable
        onFilter={onFilter}
        idExtractor={idExtractor}
        hiddenColumns={hiddenColumns}
        onHiddenColumnsChange={setHiddenColumns}
        data={tableItems}
        currentPageNumber={data?.page}
        columns={columnsTable}
        totalItems={data?.total || 10}
        actions={rowActions}
        leftToolbar={<QrCodeTableFilterAction />}
      />

      <ResponsiveModal noShowAnimation noPadding size='big' onClose={hideModal} open={isOpen}>
        <div className='h-100 p-5 d-flex flex-column'>
          <div className='d-flex justify-content-end'>
            <MetronicIconButton
              variant='text'
              size='md'
              iconType='Navigation'
              iconName='Close'
              tooltip='Close Modal'
              onClick={hideModal}
            />
          </div>
          <div className='flex-grow-1'>
            <SeatMapSelectionPortalInput
              classNameSeatMApEditor='h-100'
              columns={columns}
              rows={rows}
              occupied={occupied}
              shared={shared}
              extra={extra}
              disabled={disabled}
              hidden={hidden}
              isRightToLeft={isRightToLeft}
              isBottomToTop={isBottomToTop}
            />
          </div>
        </div>
      </ResponsiveModal>
    </>
  )
}

const columnsTable: TableColumnOptions<ReservationDetailsModel>[] = [
  {
    field: 'code',
    label: 'Code',
    sortable: true,
    dataExtract: (data) => data.code,
    cellStyle: ColumnStyle.CODE,
  },
  {
    field: 'name',
    label: 'Reservation',
    sortable: true,
    hideable: true,
    cellStyle: ColumnStyle.NAME,
  },
  {
    field: 'description',
    label: 'Description',
    cellStyle: ColumnStyle.DESCRIPTION,
  },
  {
    field: 'customerCode',
    label: 'Customer',
    sortable: true,
    hideable: true,
    render: ({data}) => (
      <Link to={`/customer/${data.customerCode}/booking`}>{data.customerName}</Link>
    ),
  },
  {
    field: 'type',
    label: 'Reservation Type',
    sortable: true,
    hideable: true,
    dataExtract: (data) => data.reservationModuleType,
  },

  {
    field: 'event',
    label: 'Event',
    sortable: true,
    hideable: true,
    dataExtract: (data) => data.eventName,
    cellStyle: ColumnStyle.NAME,
  },

  {
    field: 'status',
    label: 'Status',
    sortable: true,
    hideable: true,
    render: ReservationDetailsStatusColumn,
  },
]
