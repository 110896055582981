import {useCallback, useMemo} from 'react'
import {SeatMapValue} from '../SeatMapValue'
import {SeatValue} from '../SeatValue'

export interface SVGSeatProps {
  rowIndex: number
  columnIndex: number
  onClick?: (seat: SeatValue) => void
  stroke?: string
  color?: string
  x: number
  y: number
  height: number
  width: number
  hidden?: boolean
  value: SeatValue
}

export const SVGSeat = ({
  columnIndex,
  rowIndex,
  onClick,
  hidden,
  color,
  width,
  height,
  x,
  y,
  stroke,
  value,
}: SVGSeatProps) => {
  const seatLabel = useMemo(
    () => `${SeatMapValue.getRowLetter(rowIndex + 1)}${columnIndex + 1}`,
    [columnIndex, rowIndex]
  )
  const handleClick = useCallback(() => {
    onClick?.(value)
  }, [onClick, value])

  if (hidden) {
    return null
  }

  return (
    <>
      <rect
        x={x}
        y={y}
        height={height}
        width={width}
        stroke={stroke || 'white'}
        fill={color}
        strokeWidth={1}
        onClick={handleClick}
      ></rect>
      <text
        x={x + width / 2}
        y={y + height / 2}
        dominantBaseline='middle'
        fill='white'
        textAnchor='middle'
        onClick={handleClick}
      >
        {seatLabel}
      </text>
    </>
  )
}
