import type {FormikContextType} from 'formik'
import {useMemo, useCallback, useState, ChangeEvent, useEffect} from 'react'
import * as Yup from 'yup'
import {
  DrawerFormContainerProps,
  DrawerFormContainer,
} from '../../../../../components/Drawer/DrawerFormContainer'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {ProductInput} from '../../../../../components/inputs/ProductInput/ProductInput'
import {
  ProductInputItemAttributes,
  ProductInputItemValue,
} from '../../../../../components/inputs/ProductInput/ProductInputItem'
import {FilterSearchableSelectInput} from '../../../../../components/inputs/SearchableSelect/FilterSearchableSelectInput'
import {SeatMapValueObject} from '../../../../../components/inputs/SeatMapInput/SeatMapValue'
import {SelectInputItem} from '../../../../../components/inputs/SelectInput'
import {SelectTreeOptionGroup} from '../../../../../components/inputs/SelectTreeNative/SelectTreeOptionGroup'
import {LocationModel} from '../../../../../models/acs/LocationModel'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {EventModel} from '../../../../../models/ems/EventModel'
import {ProductModel} from '../../../../../models/ems/ProductModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {selectItemMapper} from '../../../../../utils/idExtractor'
import {ApiTree} from '../../../../../utils/Tree/ApiTree'
import {
  GetCustomerListFlatten,
  GetProductsReservationByEvent,
  GetLocationsReservationByEvent,
  GetEvents,
} from '../../../redux/CustomerPortalCRUD'
import {LocationInput} from '../../LocationInput/LocationInput'

export interface LocationCreateParams {
  code: string
  details: SeatMapValueObject
}
export interface ReservationFormValues {
  eventCode: string | null
  customer: CustomerModel | null
  products: ProductInputItemValue[]
  locations: LocationCreateParams[]
}

export const roleFormValidationSchema = Yup.object().shape({
  eventCode: Yup.string().required(),
  customer: Yup.object().required(),
  products: Yup.array().test(
    'has-atleast-one',
    'Please add at lease one product',
    (value, parent) => {
      if (
        (value && value.length > 0) ||
        (parent.parent.locations.length > 0 &&
          !(
            parent.parent.locations.length === 1 && parent.parent.locations[0].details === undefined
          ))
      ) {
        return true
      } else return false
    }
  ),
  locations: Yup.array().test(
    'has-atleast-one',
    'Please add at lease one location',
    (value, parent) => {
      if (
        (value && value.length > 0) ||
        (parent.parent.products.length > 0 &&
          !(parent.parent.products.length === 1 && parent.parent.products[0].value === undefined))
      ) {
        return true
      } else return false
    }
  ),
})

export interface ReservationFormProps extends Omit<DrawerFormContainerProps, 'isSubmitDisabled'> {
  formik: FormikContextType<ReservationFormValues>
  disabledFields?: Record<string, boolean>
  isOpen: boolean
  event?: EventModel
}

export const ReservationForm = ({
  formik,
  disabledFields,
  isOpen,
  event,
  ...formProps
}: ReservationFormProps) => {
  const [products, setProducts] = useState<ProductModel[]>([])
  const [customers, setCustomers] = useState<GlobalSearchModel<CustomerModel>>()
  const [location, setLocation] = useState<LocationModel[] | null>(null)
  const [selectedLocation, setSelectedLocation] = useState('')
  const [allEvents, setAllEvents] = useState<EventModel[]>([])

  const getProducts = useCallback(async () => {
    const eventCode = formik.values.eventCode
    if (eventCode) {
      const {data} = await GetProductsReservationByEvent(eventCode)
      setProducts(data)
    }
  }, [formik.values.eventCode])

  const getLocations = useCallback(async () => {
    const eventCode = formik.values.eventCode
    if (eventCode) {
      const {data} = await GetLocationsReservationByEvent(eventCode)
      setLocation(data)
    }
  }, [formik.values.eventCode])

  const getCustomers = useCallback(async (filters?: FilterModel) => {
    const {data} = await GetCustomerListFlatten({
      filters: {
        status: ['active', 'pending'],
        type: ['reseller'],
        search: filters?.filters?.search,
      },
    })

    if (data.data && Array.isArray(data.data))
      setCustomers(data as GlobalSearchModel<CustomerModel>)
  }, [])

  const getAllEvents = useCallback(async () => {
    const {data} = await GetEvents()
    setAllEvents(data.data)
  }, [])

  useEffect(() => {
    if (formik.values.eventCode && isOpen) {
      getLocations()
      getProducts()
    }
  }, [formik.values.eventCode, getLocations, getProducts, isOpen])

  useOnChange(isOpen, () => {
    if (isOpen) {
      if (!event) getAllEvents()
      getCustomers()
    } else {
      setAllEvents([])
      setProducts([])
      setLocation(null)
      setSelectedLocation('')
    }
  })

  const productInputItems = useMemo((): ProductInputItemAttributes[] => {
    return products.map((product) => {
      return {
        label: product.name,
        value: product.code,
        type: 'product',
      }
    })
  }, [products])

  const handleOnChangeProduct = useCallback(
    (newValues: ProductInputItemValue[]) => {
      formik.setFieldValue('products', newValues)
    },
    [formik]
  )

  const handleCustomerChange = useCallback(
    (value: CustomerModel | null) => {
      formik.setFieldValue('customer', value)
    },
    [formik]
  )

  useOnChange(event, () => {
    if (event) formik.setFieldValue('eventCode', event.code)
  })

  const handleLocationChange = useCallback((values: string[]) => {
    const value = values[0] || ''
    setSelectedLocation(value)
  }, [])

  const hasProductsOrBundlesAvailable = useMemo(() => {
    return products.length > 0
  }, [products])

  const hasLocationsAvailable = useMemo(() => {
    return location && location.length > 0
  }, [location])

  const locationItems = useMemo(() => {
    if (location) {
      const locationTree = new ApiTree(location)
      return locationTree.getTreeSelectItems()
    }
    return []
  }, [location])

  const locationvalue = useMemo(() => {
    if (selectedLocation) {
      return [selectedLocation]
    }
    return []
  }, [selectedLocation])

  const eventInputItems = useMemo((): SelectInputItem[] => {
    return allEvents.map((event) => {
      return {
        label: event.name,
        value: event.code,
      }
    })
  }, [allEvents])

  const handleEventCodeOnChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value
      formik.setFieldValue('eventCode', value)
      formik.setFieldValue('products', [])
    },
    [formik]
  )

  return (
    <DrawerFormContainer
      isSubmitDisabled={formik.isSubmitting || !formik.isValid}
      onSubmit={formik.handleSubmit}
      {...formProps}
    >
      {!event && (
        <SelectTreeOptionGroup
          label='Event'
          placeholder='Select an Activity or an Event'
          items={[{label: 'Events', items: eventInputItems}]}
          disabled={disabledFields?.eventCode}
          {...formik.getFieldProps('eventCode')}
          onChange={handleEventCodeOnChange}
        />
      )}
      <FilterSearchableSelectInput
        label='Customer'
        placeholder='Select Customer'
        itemMapper={selectItemMapper}
        searchResult={customers}
        onFilter={getCustomers}
        disabled={disabledFields?.customer}
        value={formik.values.customer}
        onChange={handleCustomerChange}
      />
      <ProductInput
        disabled={!hasProductsOrBundlesAvailable}
        items={productInputItems}
        label='Product'
        onChange={handleOnChangeProduct}
        values={formik.values.products}
      />

      <LocationInput
        event={event}
        formik={formik}
        locationvalue={locationvalue}
        locationItems={locationItems}
        handleLocationChange={handleLocationChange}
        disabled={!hasLocationsAvailable}
      />
    </DrawerFormContainer>
  )
}
