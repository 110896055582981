import {DeleteRowAction} from './DeleteRowAction'
import {EditRowAction} from './EditRowAction'

export interface ManageRowActionProps<T> {
  data: T
  onEdit?: (data: T) => void
  onDelete?: (data: T) => void
  loading?: boolean
}

export const ManageRowAction = <T,>({data, loading, onDelete, onEdit}: ManageRowActionProps<T>) => {
  return (
    <>
      <EditRowAction loading={loading} data={data} onClick={onEdit} />
      <DeleteRowAction loading={loading} data={data} onClick={onDelete} />
    </>
  )
}
