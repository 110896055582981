import {useMemo} from 'react'
import Moment from 'react-moment'
import {KTSVG} from '../../../../_metronic/helpers'
import {DetailHeaderContent} from '../../../components/DetailHeader/DetailHeaderContent'
import {DetailHeaderTabList} from '../../../components/DetailHeader/DetailHeaderTabList'
import {AuthDetailHeaderTabProps} from '../../../components/DetailHeader/useAuthTabs'
import {ImageInputValue} from '../../../components/inputs/FileInput/ImageInputValue'
import {Paper} from '../../../components/utils/Paper'
import {EventModel} from '../../../models/ems/EventModel'
import {useAppConfig} from '../../app-config/hooks/useAppConfig'
import {useCustomerAuth} from '../hooks/useCustomerAuth'

export interface PortalEventDetailTabsProps {
  event?: EventModel | null
}

export const PortalEventDetailTabs = ({event}: PortalEventDetailTabsProps) => {
  const auth = useCustomerAuth()
  const {staticUrls} = useAppConfig()

  const src = useMemo(() => {
    if (event && event.emsFile) {
      const imageValue = new ImageInputValue(staticUrls.public, event.emsFile)
      return imageValue.url
    }

    return null
  }, [event, staticUrls.public])
  const allTabs = useMemo((): AuthDetailHeaderTabProps[] => {
    if (event) {
      const basePath = `/events/${event.code}`
      const tabs: AuthDetailHeaderTabProps[] = [
        {
          label: 'Overview',
          link: {
            to: `${basePath}/overview`,
          },
        },
      ]

      tabs.push({
        label: 'Bookings',
        link: {
          to: `${basePath}/bookings`,
        },
      })

      tabs.push({
        label: 'Tickets',
        link: {
          to: `${basePath}/tickets`,
        },
      })
      if (auth?.getUser().type === 'reseller') {
        tabs.push({
          label: 'Reservations',
          link: {
            to: `${basePath}/reservation`,
          },
        })
      }

      return tabs
    }
    return []
  }, [auth, event])

  const headerContent = useMemo(() => {
    if (event) {
      return (
        <DetailHeaderContent
          description={event?.description}
          left={
            <>
              <div className='d-flex flex-wrap mb-2 pe-2'>
                <span className='d-flex align-items-center me-5'>
                  <KTSVG path='/media/icons/duotone/Map/Marker1.svg' className='svg-icon-4 me-1' />
                  {event?.venue?.name}
                </span>
              </div>
              <div className='d-flex flex-wrap mb-4 pe-2'>
                <span className='d-flex align-items-center text-muted me-5 mb-2'>
                  <KTSVG
                    path='/media/icons/duotone/Interface/Calendar.svg'
                    className='svg-icon-4 me-1'
                  />
                  <Moment format='MMMM DD, YYYY'>{event?.startedAt}</Moment>
                  <span className='mx-2'>-</span>
                  <Moment format='MMMM DD, YYYY'>{event?.endedAt}</Moment>
                </span>
              </div>
            </>
          }
          right={
            src && (
              <img
                style={{
                  height: '50px',
                }}
                className='img-fluid'
                src={src}
                alt={event?.name}
              />
            )
          }
        />
      )
    }
    return null
  }, [event, src])

  return (
    <>
      {headerContent}
      <Paper className='px-3 mb-6' rounded>
        <DetailHeaderTabList tabs={allTabs} />
      </Paper>
    </>
  )
}
