import {useMemo} from 'react'
import {SeatMapCanvasInputProps} from '../../../../components/inputs/SeatMapInput/SeatMapCanvasInput'
import {TreeNodeItem} from '../../../../components/inputs/TreeSelect/TreeSelect'
import {ResponsiveModal} from '../../../../components/modals/ResponsiveModal'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {ReservationDetailSeatMaps} from './ReservationDetailsSeatMaps'
import {SeatMapValueObject} from '../../../../components/inputs/SeatMapInput/SeatMapValue'
import {EventModel} from '../../../../models/ems/EventModel'

export interface SeatMapSelectionModalInputProps extends SeatMapCanvasInputProps {
  open: boolean
  onHide: () => void
  count: number
  locationItems?: TreeNodeItem[]
  locationvalue: string[]
  onLocationChange?: (value: string) => void
  locationCode?: string
  disableSubmit?: boolean
  modalTitle?: string | ((remaining: number) => string)
  loading?: boolean
  disableSelection?: boolean
  handleSeats: (seats: SeatMapValueObject) => void
  event?: EventModel
}

export const SeatMapSelectionModalInput = ({
  open,
  onHide,
  count,
  value,
  modalTitle,
  locationItems,
  locationvalue,
  event,
  handleSeats,
}: SeatMapSelectionModalInputProps) => {
  const remaining = useMemo(() => {
    const selectCount = value?.getValueCount() || 0
    return count - selectCount
  }, [value, count])

  const header = useMemo(() => {
    const title = `Please select seats: ${remaining} remaining.`
    let subtitle: string = ''
    if (modalTitle) {
      if (typeof modalTitle === 'string') {
        subtitle = modalTitle
      } else {
        subtitle = modalTitle(remaining)
      }
    }
    return (
      <div className='w-100 d-flex justify-content-between'>
        <h2>{title}</h2>
        <h2>{subtitle}</h2>
      </div>
    )
  }, [modalTitle, remaining])

  return (
    <ResponsiveModal noShowAnimation noPadding size='big' onClose={onHide} open={open}>
      <div className='h-100 p-5 d-flex flex-column'>
        <div className='d-flex'>
          <div className='px-5 pt-5 flex-grow-1'>{header}</div>
          <MetronicIconButton
            variant='text'
            size='sm'
            iconType='Navigation'
            iconName='Close'
            tooltip='Close Modal'
            onClick={onHide}
          />
        </div>
        <div className='flex-grow-1'>
          {locationItems && (
            <ReservationDetailSeatMaps
              event={event}
              locationItems={locationItems}
              locationvalue={locationvalue}
              onHide={onHide}
              handleSeats={handleSeats}
            />
          )}
        </div>
      </div>
    </ResponsiveModal>
  )
}
