import {useMemo} from 'react'

export interface Page404Props {
  link?: string
}

export const Page404 = ({link}: Page404Props) => {
  const image = useMemo(() => {
    return (
      <a target='_blank' rel='noreferrer' href='https://solaax.com'>
        <img
          alt='tedx'
          src='/media/saas/tedx-solaax-1.png'
          className='img-fluid w-500px w-100 mb-8'
        />
      </a>
    )
  }, [])

  return (
    <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
      {image}
      <h1 className='text-center mt-5'>404</h1>
      <p className='text-center fs-4'>This page is not available.</p>
      {/* {link && (
        <a className='text-center text-decoration-underline' href={link}>
          Take me somewhere else.
        </a>
      )} */}
      <div className='d-flex gap-7 mt-10'>
        <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/tedxeindhoven'>
          <img alt='tedx linkedin' src='/media/svg/social-logos/tedx/linkedin.png' width='30px' />
        </a>

        <a target='_blank' rel='noreferrer' href='https://twitter.com/tedxeindhoven'>
          <img alt='tedx twitter' src='/media/svg/social-logos/tedx/twitter.png' width='30px' />
        </a>

        <a target='_blank' rel='noreferrer' href='https://www.instagram.com/tedxeindhoven'>
          <img alt='tedx instagram' src='/media/svg/social-logos/tedx/instagram.png' width='30px' />
        </a>
      </div>
      <a target='_blank' rel='noreferrer' href='https://solaax.com'>
        <img
          alt='powered by solaax'
          src='/media/saas/powered-by-solaax.png'
          className='h-50px mt-12'
        />
      </a>
    </div>
  )
}
