import {useCallback} from 'react'
import {MetronicIconButton} from '../../inputs/MetronicIconButton'
import {QRCodeModalInput} from '../../inputs/QRCode/QRCodeInputModal'
import {useModalState} from '../../modals/useModalState'
import {QrCodeDataParser} from '../../../utils/Qr/QrCodeDataParser'
import {useTableFilterState} from '../useTableFilterState'

export const QrCodeTableFilterAction = () => {
  const {setSearch} = useTableFilterState()
  const {open, hide, isOpen} = useModalState()

  const closeQrCodeModal = useCallback(
    (value) => {
      hide()
      if (value) {
        try {
          const qrcode = new QrCodeDataParser(value)
          setSearch(qrcode.getTicketCode())
        } catch (e) {
          setSearch(value)
        }
      }
    },
    [hide, setSearch]
  )

  const openQrCodeModal = useCallback(() => {
    open()
  }, [open])

  const handleOpenModal = useCallback(() => {
    openQrCodeModal()
  }, [openQrCodeModal])

  return (
    <>
      <MetronicIconButton
        size='md'
        iconType='Shopping'
        iconName='Barcode-read'
        tooltip='Scan QR Code'
        onClick={handleOpenModal}
      />
      <QRCodeModalInput
        hideButton
        hideLabel
        open={isOpen}
        onClose={hide}
        onSubmit={closeQrCodeModal}
        autoSubmit
      />
    </>
  )
}
