import axios from 'axios'
import {FilterModel} from '../../../../models/FilterModel'
import {
  MerchandiseOutletModel,
  MerchandiseOutletModelCreateFormDataParams,
} from '../../../../models/merchandise/MerchandiseOutletModel'

import {
  MerchandiseProductCategoryModel,
  MerchandiseProductCategoryModelCreateParams,
} from '../../../../models/merchandise/MerchandiseProductCategoryModel'

import {
  MerchandiseProductModel,
  MerchandiseProductModelCreateFormDataParams,
} from '../../../../models/merchandise/MerchandiseProductModel'

import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'

const CRUD_OUTLET = '/merchandise-outlet'
const CRUD_PRODUCT = '/merchandise-product'
const CRUD_PRODUCT_CATEGORY = '/merchandise-product-category'

// OUTLET
export const GetOutlets = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<MerchandiseOutletModel>>(`${CRUD_OUTLET}/list`, filters)

export const GetOutletByCode = (code: string) =>
  axios.get<MerchandiseOutletModel>(`${CRUD_OUTLET}/${code}`)
export const DeleteOutlets = (codes: string[]) =>
  axios.delete<unknown>(`${CRUD_OUTLET}`, {data: {codes}})
export const PostOutlet = (data: MerchandiseOutletModelCreateFormDataParams) =>
  axios.post<unknown>(`${CRUD_OUTLET}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
export const PutOutlet = (outletCode: string, data: MerchandiseOutletModelCreateFormDataParams) =>
  axios.put<unknown>(`${CRUD_OUTLET}/${outletCode}`, data.getFormData())

// PRODUCT
export const GetProducts = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<MerchandiseProductModel>>(`${CRUD_PRODUCT}/list`, filters)
export const GetProductByCode = (code: string) =>
  axios.get<MerchandiseProductModel>(`${CRUD_PRODUCT}/${code}`)
export const DeleteProducts = (codes: string[]) =>
  axios.delete<unknown>(`${CRUD_PRODUCT}`, {data: {codes}})
export const PostProduct = (data: MerchandiseProductModelCreateFormDataParams) =>
  axios.post<unknown>(`${CRUD_PRODUCT}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
export const PutProduct = (code: string, data: MerchandiseProductModelCreateFormDataParams) =>
  axios.put<unknown>(`${CRUD_PRODUCT}/${code}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

// PRODUCT CATEGORY
export const GetProductCategories = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<MerchandiseProductCategoryModel>>(
    `${CRUD_PRODUCT_CATEGORY}/list`,
    filters
  )
export const GetProductCategoryByCode = (code: string) =>
  axios.get<MerchandiseProductCategoryModel>(`${CRUD_PRODUCT_CATEGORY}/${code}`)
export const DeleteProductCategories = (codes: string[]) =>
  axios.delete<unknown>(`${CRUD_PRODUCT_CATEGORY}`, {data: {codes}})
export const PostProductCategory = (data: MerchandiseProductCategoryModelCreateParams) =>
  axios.post<unknown>(`${CRUD_PRODUCT_CATEGORY}`, data)
export const PutProductCategory = (
  code: string,
  data: MerchandiseProductCategoryModelCreateParams
) => axios.put<unknown>(`${CRUD_PRODUCT_CATEGORY}/${code}`, data)
