import {Link} from 'react-router-dom'
import {EventModel} from '../../../../../models/ems/EventModel'
import {ReservationPortalModel} from '../../../../../models/ems/ReservationMedel'

interface ReservationCodeColumnProps {
  data: ReservationPortalModel
  event?: EventModel
}
export const ReservationCodeColumn = ({data, event}: ReservationCodeColumnProps) => {
  if (event) {
    return (
      <Link
        className='min-w-80px'
        to={`/events/${event.code}/reservation/customer/${data.customer.code}/reservation-details/${data?.code}`}
      >
        {data.code}
      </Link>
    )
  }

  return null
}
