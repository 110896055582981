import axios from 'axios'
import {FilterModel} from '../../../../models/FilterModel'
import {LocationOutletFilterModel} from '../../../../models/fnb/LocationOutletFilterModel'
import {OrderModel, OrderModelCreateParams} from '../../../../models/fnb/OrderModel'
import {OutletImportModel} from '../../../../models/fnb/OutletImportModel'
import {OutletLogModel} from '../../../../models/fnb/OutletLogModel'
import {OutletModel, OutletModelCreateFormDataParams} from '../../../../models/fnb/OutletModel'
import {ProductCategoryImportModel} from '../../../../models/fnb/ProductCategoryImportModel'
import {
  ProductCategoryModel,
  ProductCategoryModelCreateParams,
} from '../../../../models/fnb/ProductCategoryModel'
import {ProductImportModel} from '../../../../models/fnb/ProductImportModel'
import {ProductModel, ProductModelCreateFormDataParams} from '../../../../models/fnb/ProductModel'
import {
  ReservationModel,
  ReservationModelCreateParams,
  ReservationModelStatus,
} from '../../../../models/fnb/ReservationModel'
import {UserImportModel} from '../../../../models/fnb/UserImportModel'
import {UserModel, UserModelCreateParams} from '../../../../models/fnb/UserModel'
import {WidgetModel} from '../../../../models/fnb/WidgetModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {DateUtil} from '../../../../utils/DateUtil'

const CRUD_OUTLET = '/outlet'
const CRUD_PRODUCT = '/fnb-product'
const CRUD_PRODUCT_CATEGORY = '/fnb-product-category'
const CRUD_USER = '/fnb-user'
const CRUD_ORDER = '/order'

export const GetOutlets = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<OutletModel>>(`${CRUD_OUTLET}/list`, filters)
export const GetOutletByCode = (code: string) => axios.get<OutletModel>(`${CRUD_OUTLET}/${code}`)
export const DeleteOutlets = (codes: string[]) =>
  axios.delete<unknown>(`${CRUD_OUTLET}`, {data: {codes}})
export const PostOutlet = (data: OutletModelCreateFormDataParams) =>
  axios.post<unknown>(`${CRUD_OUTLET}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
export const PutOutlet = (outletCode: string, data: OutletModelCreateFormDataParams) =>
  axios.put<unknown>(`${CRUD_OUTLET}/${outletCode}`, data.getFormData())

export const GetProducts = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(`${CRUD_PRODUCT}/list`, filters)
export const GetProductByCode = (code: string) => axios.get<ProductModel>(`${CRUD_PRODUCT}/${code}`)
export const DeleteProducts = (codes: string[]) =>
  axios.delete<unknown>(`${CRUD_PRODUCT}`, {data: {codes}})
export const PostProduct = (data: ProductModelCreateFormDataParams) =>
  axios.post<unknown>(`${CRUD_PRODUCT}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
export const PutProduct = (code: string, data: ProductModelCreateFormDataParams) =>
  axios.put<unknown>(`${CRUD_PRODUCT}/${code}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const GetProductCategories = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductCategoryModel>>(`${CRUD_PRODUCT_CATEGORY}/list`, filters)
export const GetProductCategoryByCode = (code: string) =>
  axios.get<ProductCategoryModel>(`${CRUD_PRODUCT_CATEGORY}/${code}`)
export const DeleteProductCategories = (codes: string[]) =>
  axios.delete<unknown>(`${CRUD_PRODUCT_CATEGORY}`, {data: {codes}})
export const PostProductCategory = (data: ProductCategoryModelCreateParams) =>
  axios.post<unknown>(`${CRUD_PRODUCT_CATEGORY}`, data)
export const PutProductCategory = (code: string, data: ProductCategoryModelCreateParams) =>
  axios.put<unknown>(`${CRUD_PRODUCT_CATEGORY}/${code}`, data)

export const GetUserByCode = (code: string) => axios.get<UserModel>(`${CRUD_USER}/${code}`)
export const GetUsers = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<UserModel>>(`${CRUD_USER}/list`, filters)
export const DeleteUsers = (codes: string[]) =>
  axios.delete<unknown>(`${CRUD_USER}`, {data: {codes}})
export const PostUser = (data: UserModelCreateParams) => axios.post<unknown>(`${CRUD_USER}`, data)
export const PutUser = (code: string, data: UserModelCreateParams) =>
  axios.put<unknown>(`${CRUD_USER}/${code}`, data)
export const ResetUserCredentials = (outletCode: string, code: string) =>
  axios.post<unknown>(`${CRUD_USER}/reset`, {outletCode, code})

export const GetOrderByCode = (code: string) => axios.get<OrderModel>(`${CRUD_ORDER}/${code}`)
export const GetOrders = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<OrderModel>>(`${CRUD_ORDER}/list`, filters)
export const DeleteOrders = (codes: string[]) =>
  axios.delete<unknown>(`${CRUD_ORDER}`, {data: {codes}})
export const PostOrder = (data: OrderModelCreateParams) =>
  axios.post<unknown>(`${CRUD_ORDER}`, data)
export const PutOrder = (code: string, data: OrderModelCreateParams) =>
  axios.put<unknown>(`${CRUD_ORDER}/${code}`, data)

export const GetOutletLogs = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<OutletLogModel>>(`/log/outlet`, filters)

export const SearchTableReservations = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ReservationModel>>(`/table-reservation/list`, filter)
export const CreateTableReservation = (payload: ReservationModelCreateParams) =>
  axios.post<unknown>(`/table-reservation`, payload)
export const UpdateTableReservation = (
  reservationCode: string,
  payload: ReservationModelCreateParams
) => axios.put<unknown>(`/table-reservation/${reservationCode}`, payload)
export const UpdateTableReservationStatus = (
  reservationCode: string,
  params: {outletCode: string; status: ReservationModelStatus; reason?: string; tableNo?: string}
) => axios.post(`/table-reservation/${reservationCode}/status`, params)
export const GetReservationByCode = (reservationCode: string) =>
  axios.get(`/table-reservation/${reservationCode}`)

export const GetLocationOutlets = (filters?: LocationOutletFilterModel) =>
  axios.post<OutletModel[]>(`/outlet/location`, filters)
export const DownloadOutletReports = (outletCode: string, date: Date) =>
  axios.post<Blob>(
    `/outlet/report`,
    {
      outletCode,
      date: DateUtil.convertDateToApiString(date),
    },
    {
      responseType: 'blob',
    }
  )

export const CreateOutletImport = (
  eventCode: string,
  mapping: string[],
  csv: File,
  hasHeaders: boolean = true
) => {
  const formData = new FormData()
  formData.append('eventCode', eventCode)
  formData.append('file', csv)
  mapping.forEach((map, index) => {
    formData.append(`map[${index}]`, map)
  })
  formData.append('hasHeaders', String(hasHeaders))
  return axios.post<GlobalSearchModel<OutletImportModel>>(`/fnb-import/outlet`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export const ProcessOutletImport = () => axios.get(`/fnb-import/process-outlet`)
export const ListOutletImport = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<OutletImportModel>>(`/fnb-import/outlet/list`, filter)

export const CreateProductCategoryImport = (
  eventCode: string,
  mapping: string[],
  csv: File,
  hasHeaders: boolean = true
) => {
  const formData = new FormData()
  formData.append('eventCode', eventCode)
  formData.append('file', csv)
  mapping.forEach((map, index) => {
    formData.append(`map[${index}]`, map)
  })
  formData.append('hasHeaders', String(hasHeaders))
  return axios.post<GlobalSearchModel<ProductCategoryImportModel>>(
    `/fnb-import/fnb-product-category`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}
export const ProcessProductCategoryImport = () =>
  axios.get<GlobalSearchModel<ProductCategoryImportModel>>(
    `/fnb-import/process-fnb-product-category`
  )
export const ListProductCategoryImport = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductCategoryImportModel>>(
    `/fnb-import/fnb-product-category/list`,
    filter
  )

export const CreateProductImport = (
  eventCode: string,
  mapping: string[],
  csv: File,
  hasHeaders: boolean = true
) => {
  const formData = new FormData()
  formData.append('eventCode', eventCode)
  formData.append('file', csv)
  mapping.forEach((map, index) => {
    formData.append(`map[${index}]`, map)
  })
  formData.append('hasHeaders', String(hasHeaders))
  return axios.post<GlobalSearchModel<ProductImportModel>>(`/fnb-import/fnb-product`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export const ProcessProductImport = () =>
  axios.get<GlobalSearchModel<ProductImportModel>>(`/fnb-import/process-fnb-product`)
export const ListProductImport = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductImportModel>>(`/fnb-import/fnb-product/list`, filter)

export const ProcessUserImport = () =>
  axios.get<GlobalSearchModel<UserImportModel>>(`/fnb-import/process-fnb-user`)
export const ListUserImport = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<UserImportModel>>(`/fnb-import/fnb-user/list`, filter)
export const CreateUserImport = (
  eventCode: string,
  mapping: string[],
  csv: File,
  hasHeaders: boolean = true
) => {
  const formData = new FormData()
  formData.append('eventCode', eventCode)
  formData.append('file', csv)
  mapping.forEach((map, index) => {
    formData.append(`map[${index}]`, map)
  })
  formData.append('hasHeaders', String(hasHeaders))
  return axios.post<GlobalSearchModel<UserImportModel>>(`/fnb-import/fnb-user`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const GetWidgetData = (
  widgets: string[],
  eventCode: string,
  date: string,
  outletCode?: string
) =>
  axios.post<WidgetModel>('/widget/fnb', {
    event: eventCode,
    outlet: outletCode,
    date,
    widgets,
  })
