import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {FilterModel} from '../../../../models/FilterModel'
import {
  MerchandiseOutletModel,
  MerchandiseOutletModelCreateParams,
} from '../../../../models/merchandise/MerchandiseOutletModel'
import {
  MerchandiseProductCategoryModel,
  MerchandiseProductCategoryModelCreateParams,
} from '../../../../models/merchandise/MerchandiseProductCategoryModel'
import {
  MerchandiseProductModel,
  MerchandiseProductModelCreateParams,
} from '../../../../models/merchandise/MerchandiseProductModel'

import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {GetOutlets, GetProductCategories, GetProducts} from './MerchandiseCRUD'

interface ActionWithPayload<T> extends Action {
  payload: T
}

interface IPagesState {
  outlets?: GlobalSearchModel<MerchandiseOutletModel>
  products?: GlobalSearchModel<MerchandiseProductModel>
  productCategories?: GlobalSearchModel<MerchandiseProductCategoryModel>
}

const initialAuthState: IPagesState = {
  outlets: undefined,
  products: undefined,
  productCategories: undefined,
}

export const reducer = persistReducer(
  {storage, key: 'webapp-merchandise', whitelist: []},
  (state: IPagesState = initialAuthState, action: Partial<ActionWithPayload<IPagesState>>) => {
    switch (action.type) {
      case actionTypes.outlets.search.success: {
        const outlets = action.payload?.outlets
        return {...state, outlets}
      }
      case actionTypes.products.search.success: {
        const products = action.payload?.products
        return {...state, products}
      }
      case actionTypes.productCategories.search.success: {
        const productCategories = action.payload?.productCategories
        return {...state, productCategories}
      }

      default:
        return state
    }
  }
)

export function* saga() {
  yield takeLatest(
    [
      actionTypes.outlets.search.execute,
      actionTypes.outlets.create.success,
      actionTypes.outlets.update.success,
      actionTypes.outlets.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select(
          (state) => state.system.filters['merchandise-outlet']
        )
        const {data} = yield GetOutlets(filter)
        yield put(actions.outlets.searchSuccess(data))
      } catch (e) {
        yield put(actions.outlets.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.productCategories.search.execute,
      actionTypes.productCategories.create.success,
      actionTypes.productCategories.update.success,
      actionTypes.productCategories.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select(
          (state) => state.system.filters['merchandise-product-category']
        )
        const {data} = yield GetProductCategories(filter)
        yield put(actions.productCategories.searchSuccess(data))
      } catch (e) {
        yield put(actions.productCategories.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.products.search.execute,
      actionTypes.products.create.success,
      actionTypes.products.update.success,
      actionTypes.products.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select(
          (state) => state.system.filters['merchandise-product']
        )
        const {data} = yield GetProducts(filter)
        yield put(actions.products.searchSuccess(data))
      } catch (e) {
        yield put(actions.products.searchFailed())
      }
    }
  )
}

export const actions = {
  outlets: {
    search: () => ({type: actionTypes.outlets.search.execute}),
    searchSuccess: (data: GlobalSearchModel<MerchandiseOutletModel>) => ({
      type: actionTypes.outlets.search.success,
      payload: {outlets: data},
    }),
    searchFailed: () => ({type: actionTypes.outlets.search.failed}),

    create: (payload: MerchandiseOutletModelCreateParams) => ({
      type: actionTypes.outlets.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.outlets.create.success}),
    createFailed: () => ({type: actionTypes.outlets.create.failed}),

    update: (payload: MerchandiseOutletModelCreateParams) => ({
      type: actionTypes.outlets.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.outlets.update.success}),
    updateFailed: () => ({type: actionTypes.outlets.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.outlets.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.outlets.delete.success}),
    deleteFailed: () => ({type: actionTypes.outlets.delete.failed}),
  },
  products: {
    search: () => ({type: actionTypes.products.search.execute}),
    searchSuccess: (data: GlobalSearchModel<MerchandiseProductModel>) => ({
      type: actionTypes.products.search.success,
      payload: {products: data},
    }),
    searchFailed: () => ({type: actionTypes.products.search.failed}),

    create: (payload: MerchandiseProductModelCreateParams) => ({
      type: actionTypes.products.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.products.create.success}),
    createFailed: () => ({type: actionTypes.products.create.failed}),

    update: (payload: MerchandiseProductModelCreateParams) => ({
      type: actionTypes.products.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.products.update.success}),
    updateFailed: () => ({type: actionTypes.products.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.products.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.products.delete.success}),
    deleteFailed: () => ({type: actionTypes.products.delete.failed}),
  },
  productCategories: {
    search: () => ({type: actionTypes.productCategories.search.execute}),
    searchSuccess: (data: GlobalSearchModel<MerchandiseProductCategoryModel>) => ({
      type: actionTypes.productCategories.search.success,
      payload: {productCategories: data},
    }),
    searchFailed: () => ({type: actionTypes.productCategories.search.failed}),

    create: (payload: MerchandiseProductCategoryModelCreateParams) => ({
      type: actionTypes.productCategories.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.productCategories.create.success}),
    createFailed: () => ({type: actionTypes.productCategories.create.failed}),

    update: (payload: MerchandiseProductCategoryModelCreateParams) => ({
      type: actionTypes.productCategories.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.productCategories.update.success}),
    updateFailed: () => ({type: actionTypes.productCategories.update.failed}),

    delete: (codes: string[]) => ({
      type: actionTypes.productCategories.delete.execute,
      payload: codes,
    }),
    deleteSuccess: () => ({type: actionTypes.productCategories.delete.success}),
    deleteFailed: () => ({type: actionTypes.productCategories.delete.failed}),
  },
}

const actionTypes = {
  outlets: {
    search: {
      execute: '[MERCHANDISE] SEARCH MERCHANDISE OUTLET',
      success: '[MERCHANDISE] SEARCH MERCHANDISE OUTLET SUCCESS',
      failed: '[MERCHANDISE] SEARCH MERCHANDISE OUTLET FAILED',
    },
    create: {
      execute: '[MERCHANDISE] CREATE MERCHANDISE OUTLET',
      success: '[MERCHANDISE] CREATE MERCHANDISE OUTLET SUCCESS',
      failed: '[MERCHANDISE] CREATE MERCHANDISE OUTLET FAILED',
    },
    update: {
      execute: '[MERCHANDISE] SEARCH MERCHANDISE OUTLET',
      success: '[MERCHANDISE] UPDATE MERCHANDISE OUTLET SUCCESS',
      failed: '[MERCHANDISE] UPDATE MERCHANDISE OUTLET FAILED',
    },
    delete: {
      execute: '[MERCHANDISE] DELETE MERCHANDISE OUTLET',
      success: '[MERCHANDISE] DELETE MERCHANDISE OUTLET SUCCESS',
      failed: '[MERCHANDISE] DELETE MERCHANDISE OUTLET FAILED',
    },
  },
  products: {
    search: {
      execute: '[MERCHANDISE] SEARCH MERCHANDISE PRODUCT',
      success: '[MERCHANDISE] SEARCH MERCHANDISE PRODUCT SUCCESS',
      failed: '[MERCHANDISE] SEARCH MERCHANDISE PRODUCT FAILED',
    },
    create: {
      execute: '[MERCHANDISE] CREATE MERCHANDISE PRODUCT',
      success: '[MERCHANDISE] CREATE MERCHANDISE PRODUCT SUCCESS',
      failed: '[MERCHANDISE] CREATE MERCHANDISE PRODUCT FAILED',
    },
    update: {
      execute: '[MERCHANDISE] UPDATE MERCHANDISE PRODUCT',
      success: '[MERCHANDISE] UPDATE MERCHANDISE PRODUCT SUCCESS',
      failed: '[MERCHANDISE] UPDATE MERCHANDISE PRODUCT FAILED',
    },
    delete: {
      execute: '[MERCHANDISE] DELETE MERCHANDISE PRODUCT',
      success: '[MERCHANDISE] DELETE MERCHANDISE PRODUCT SUCCESS',
      failed: '[MERCHANDISE] DELETE MERCHANDISE PRODUCT FAILED',
    },
  },
  productCategories: {
    search: {
      execute: '[MERCHANDISE] SEARCH MERCHANDISE PRODUCT CATEGORY',
      success: '[MERCHANDISE] SEARCH MERCHANDISE PRODUCT CATEGORY SUCCESS',
      failed: '[MERCHANDISE] SEARCH MERCHANDISE PRODUCT CATEGORY FAILED',
    },
    create: {
      execute: '[MERCHANDISE] CREATE MERCHANDISE PRODUCT CATEGORY',
      success: '[MERCHANDISE] CREATE MERCHANDISE PRODUCT CATEGORY SUCCESS',
      failed: '[MERCHANDISE] CREATE MERCHANDISE PRODUCT CATEGORY FAILED',
    },
    update: {
      execute: '[MERCHANDISE] UPDATE MERCHANDISE PRODUCT CATEGORY',
      success: '[MERCHANDISE] UPDATE MERCHANDISE PRODUCT CATEGORY SUCCESS',
      failed: '[MERCHANDISE] UPDATE MERCHANDISE PRODUCT CATEGORY FAILED',
    },
    delete: {
      execute: '[MERCHANDISE] DELETE MERCHANDISE PRODUCT CATEGORY',
      success: '[MERCHANDISE] DELETE MERCHANDISE PRODUCT CATEGORY SUCCESS',
      failed: '[MERCHANDISE] DELETE MERCHANDISE PRODUCT CATEGORY FAILED',
    },
  },
}
