import {useState, useEffect, useMemo, useCallback} from 'react'
import {Redirect, Route, Switch, useHistory, useParams, useRouteMatch} from 'react-router-dom'
import {EventModel} from '../../../models/ems/EventModel'

import {useLoadingState} from '../../../components/hooks/useLoadingState'
import {LoadingSpinner} from '../../../components/utils/LoadingSpinner'
import {LinkBreadCrumb} from '../../../components/layouts/DefaultLayout/LinkBreadcrumbsToolbar'
import {LinkBreadcrumbsPortalToolbar} from '../components/LinkBreadcrumbsPortalToolbar'
import {useRouteData} from '../../../components/utils/RouteWithData'
import {BookingsPortalPage} from './BookingsPortalPage'
import {TicketsPage} from './TicketsPage'
import {ReservationPage} from './ReservationPage'
import {GetEventByCode} from '../redux/CustomerPortalCRUD'
import {PortalEventDetailTabs} from '../components/PortalEventDetailTabs'
import EventPortalDetailBooking from './sub-pages/EventPortalDetailBooking'
import EventPortalDetailTickets from './sub-pages/EventPortalDetailTickets'
import EventPortalDetailReservation from './sub-pages/EventPortalDetailReservation'
import {EventOverview} from './EventOverview'

type ParamProps = {
  eventCode?: string
}

export interface PortalEventDetailRouteData {
  event?: EventModel | null
  isLoading: boolean
  breadcrumbs: LinkBreadCrumb[]
  refreshData: () => void
}

export const PortalEventDetail = () => {
  const {eventCode} = useParams<ParamProps>()
  const [event, setEvent] = useState<EventModel | null>(null)
  const {isLoading, setIsLoading} = useLoadingState([eventCode])
  const pageData = useRouteData<{breadcrumbs?: LinkBreadCrumb[]} | null>()
  const match = useRouteMatch()
  const history = useHistory()

  const name = useMemo(() => {
    if (event) {
      return event.name
    }
    return ''
  }, [event])

  const refreshEventData = useCallback(async () => {
    if (eventCode) {
      const doneLoading = setIsLoading(eventCode)
      const {data} = await GetEventByCode(eventCode)
      setEvent(data)
      doneLoading()
    }
  }, [eventCode, setIsLoading])

  const refreshData = useCallback(async () => {
    try {
      await Promise.all([refreshEventData()])
    } catch (e) {
      history.replace('/events')
    }
    if (!eventCode) {
      history.replace('/')
    }
  }, [eventCode, history, refreshEventData])

  const linkBreadCrumbs = useMemo(() => {
    const breadcrumbs: LinkBreadCrumb[] = []
    if (pageData?.data?.breadcrumbs) {
      breadcrumbs.push(...pageData.data.breadcrumbs)
    } else {
      breadcrumbs.push({
        label: 'Events',
        link: '/events',
      })
    }

    if (eventCode && event) {
      breadcrumbs.push({
        label: event.name,
        link: match.url,
      })
    }

    return breadcrumbs
  }, [event, eventCode, match.url, pageData?.data?.breadcrumbs])

  useEffect(() => {
    refreshData()
  }, [refreshData])

  return (
    <div className='d-flex flex-column h-100'>
      <Switch>
        <Route path='/events/:eventCode/overview'>
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Overview`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
            event={event}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <EventOverview event={event} />}
          </LoadingSpinner>
        </Route>
        {/* BOOKINGS */}
        <Route
          exact
          path='/events/:eventCode/bookings/customer/:customerCode/booking/:bookingCode/booking-product/:bookingProductCode/ticket'
        >
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Bookings`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <EventPortalDetailBooking event={event} />}
          </LoadingSpinner>
        </Route>
        <Route
          exact
          path='/events/:eventCode/bookings/customer/:customerCode/booking/:bookingCode/booking-product/:bookingProductCode/voucher'
        >
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Bookings`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <EventPortalDetailBooking event={event} />}
          </LoadingSpinner>
        </Route>
        <Route
          exact
          path='/events/:eventCode/bookings/customer/:customerCode/booking/:bookingCode/booking-product/:bookingProductCode/ticket/:ticketCode/seat'
        >
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Booking`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <EventPortalDetailBooking event={event} />}
          </LoadingSpinner>
        </Route>
        <Route
          exact
          path='/events/:eventCode/bookings/customer/:customerCode/booking/:bookingCode/booking-detail'
        >
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Bookings`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <EventPortalDetailBooking event={event} />}
          </LoadingSpinner>
        </Route>

        {/* TICKETS */}

        <Route
          exact
          path='/events/:eventCode/tickets/customer/:customerCode/booking/:bookingCode/booking-product/:bookingProductCode/ticket/:ticketCode/seat'
        >
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Tickets`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <EventPortalDetailTickets event={event} />}
          </LoadingSpinner>
        </Route>

        <Route
          exact
          path='/events/:eventCode/tickets/customer/:customerCode/booking/:bookingCode/booking-product/:bookingProductCode/voucher'
        >
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Tickets`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <EventPortalDetailTickets event={event} />}
          </LoadingSpinner>
        </Route>

        <Route
          exact
          path='/events/:eventCode/tickets/customer/:customerCode/booking/:bookingCode/booking-product/:bookingProductCode/ticket'
        >
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Tickets`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <EventPortalDetailTickets event={event} />}
          </LoadingSpinner>
        </Route>
        <Route path='/events/:eventCode/tickets/customer/:customerCode/booking/:bookingCode/booking-detail'>
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Tickets`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <EventPortalDetailTickets event={event} />}
          </LoadingSpinner>
        </Route>

        {/* RESERVATION */}
        <Route
          exact
          path='/events/:eventCode/reservation/customer/:customerCode/reservation-details/:reservationCode'
        >
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Reservations`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <EventPortalDetailReservation event={event} />}
          </LoadingSpinner>
        </Route>
        <Route path='/events/:eventCode/bookings'>
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Bookings`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <BookingsPortalPage event={event} />}
          </LoadingSpinner>
        </Route>
        <Route path='/events/:eventCode/tickets'>
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Tickets`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <TicketsPage event={event} />}
          </LoadingSpinner>
        </Route>
        <Route path='/events/:eventCode/reservation'>
          <LinkBreadcrumbsPortalToolbar
            title={`${name} : Reservation`}
            breadcrumbs={linkBreadCrumbs}
            isLoading={isLoading}
          ></LinkBreadcrumbsPortalToolbar>
          <PortalEventDetailTabs event={event} />
          <LoadingSpinner loading={isLoading} fullWidth>
            {event && <ReservationPage event={event} />}
          </LoadingSpinner>
        </Route>

        <Redirect from={`/events/:eventCode`} exact to={`/events/:eventCode/overview`} />
      </Switch>
    </div>
  )
}
