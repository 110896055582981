import {ReactNode} from 'react'
import {HasCode} from '../../utils/idExtractor'
import {Link} from './Link'

export interface CustomerPortalLinkProps {
  customer?: HasCode
  className?: string
  children: ReactNode
}

export const CustomerPortalLink = ({customer, className, children}: CustomerPortalLinkProps) => {
  if (customer) {
    return (
      <Link className={className} to={`/customer/${customer.code}/bookings`}>
        {children}
      </Link>
    )
  }
  return null
}
